import {
  TYPE_NONE,
  TYPE_HIDDEN,
  TYPE_REDACTOR,
} from "@components/Form"
import { HEADER_HEIGHT } from "@components"
import { hasAccess } from "@components/Authorization"

import getRedactorProps from "./getRedactorProps"

const LABEL_SEND = "Send"
const HEADER_OFFSET = 10
const PUBLISHED_STATUS = "PUBLISHED"


const useInputs = (form, tabId, campaign, saveCampaign, uploadAssets, autoSave) => {
  const canUpdateCampaigns = hasAccess(['campaigns-write'])

  const { assets, status } = campaign

  const redactorHeight = window.innerHeight - HEADER_HEIGHT - HEADER_OFFSET

  const isPublished = status === PUBLISHED_STATUS

  const isDisabled = isPublished || !canUpdateCampaigns

  const redactorProps = getRedactorProps(isDisabled, uploadAssets, assets)

  const inputs = [
    {
      name: "tagIds",
      type: TYPE_NONE,
    },
    {
      name: "applicationId",
      type: TYPE_NONE,
    },
    {
      name: "segmentId",
      type: TYPE_NONE,
    },
    {
      name: "pinpointCampaignId",
      type: TYPE_NONE,
    },
    {
      name: "status",
      type: TYPE_NONE,
    },
    {
      name: "source",
      type: TYPE_REDACTOR,
      label: "",
      inputProps: {
        autoSave,
        height: redactorHeight,
        emailTargetPath: "html",
        shouldUnwrapEmail: false,
        ...redactorProps,
      },
      itemProps: {
        style: { marginBottom: 0 },
      },
    },
    {
      name: "html",
      type: TYPE_HIDDEN,
    },
    {
      name: "assets",
      type: TYPE_HIDDEN,
    },
    {
      name: "subject",
      type: TYPE_NONE,
    },
    {
      name: "subscriptionTypeId",
      type: TYPE_NONE,
    },
    {
      name: "fromAddress",
      type: TYPE_NONE,
    },
    {
      name: "fromName",
      type: TYPE_HIDDEN,
    },
    {
      name: "replyToAddress",
      type: TYPE_NONE
    },
    {
      name: "segmentIds",
      type: TYPE_NONE
    },
    {
      name: "recipientAccountIds",
      type: TYPE_NONE
    },
    {
      name: "shouldSendImmediately",
      label: LABEL_SEND,
      type: TYPE_NONE,
    },
    {
      name: "scheduledAt",
      type: TYPE_NONE,
    },
    {
      name: "shouldUseRecipientTimeZone",
      type: TYPE_NONE,
    },
    {
      name: "name",
      type: TYPE_NONE,
    },
  ]

  return inputs
}

export default useInputs
