import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Space, Button, Form, Input, Select } from "antd"

import { W0 } from "@components"
import { useAppContext } from "@components/AppContext"
import { useOrganizations } from "@components/Organization"
import { verifyInvestmentOperation } from "@api/services/investments"
import {
  INVALID_INPUT_ERROR,
  ACCESS_DENIED_ERROR,
  DOCUMENT_NOT_FOUND_ERROR
} from "@api"

import GoToInvestmentSearch from "./GoToInvestmentSearch"

const LABEL_CODE = "Wire Reference Code"
const LABEL_SEARCH = "Don't have the code?"
const LABEL_CONTINUE = "Continue"
const LABEL_INVESTMENT_NOT_FOUND = "Investment for the specified wire reference code is not found"


const GoToInvestmentForm = ({ onSuccess }) => {
  const navigate = useNavigate()
  const organizations = useOrganizations()

  const {
    request,
    showErrorMessage,
    getOrganizationId,
    switchToOrganization,
  } = useAppContext()

  const currentOrganizationId = getOrganizationId()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(true)

  const [ projectId, setProjectId ] = useState("")
  const [ investmentId, setInvestmentId ] = useState("")
  const [ organizationId, setOrganizationId ] = useState("")

  const isCodeValid = useCallback(() => {
    if (!projectId) {
      return false
    }

    if (!investmentId) {
      return false
    }

    if (!organizationId) {
      return false
    }

    return true
  }, [
    projectId,
    investmentId,
    organizationId,
  ])

  useEffect(() => {
    const isValid = isCodeValid()

    if (!isValid) {
      setIsDisabled(true)
      return
    }

    setIsDisabled(false)
  }, [ isCodeValid ])

  const validateInvestment = async () => {
    const parameters = {
      id: investmentId,
      organizationId,
      projectId,
    }

    const expectedErrors = [
      INVALID_INPUT_ERROR,
      ACCESS_DENIED_ERROR,
      DOCUMENT_NOT_FOUND_ERROR,
    ]

    return request(verifyInvestmentOperation, parameters, expectedErrors)
      .then(() => true)
      .catch(() => {
        showErrorMessage(LABEL_INVESTMENT_NOT_FOUND)
        return false
      })
  }

  const goToInvestment = async (targetOrganizationId, targetProjectId, targetInvestmentId) => {
    const investmentPath = `/backstage/project/${targetProjectId}/investments/${targetInvestmentId}`
    const shouldSwitchOrganization = currentOrganizationId !== targetOrganizationId

    if (!shouldSwitchOrganization) {
      navigate('/backstage')
      navigate(investmentPath)
      setIsLoading(false)
      onSuccess()
      return
    }

    await switchToOrganization(targetOrganizationId, investmentPath)
    setIsLoading(false)
    onSuccess()
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const isInvestmentValid = await validateInvestment()

    if (!isInvestmentValid) {
      setIsLoading(false)
      return
    }

    await goToInvestment(organizationId, projectId, investmentId)
  }

  const formProps = {
    layout: "vertical"
  }

  const buttonProps = {
    size: "large",
    type: "primary",
    shape: "round",
    style: { width: "100%" },
    onClick: onSubmit,
    loading: isLoading,
    disabled: isDisabled
  }

  const organizationIds = organizations
    .map(organization => ({ value: organization.id }))

  const searchKey = `${organizationId}-${projectId}-${investmentId}`

  const onSelect = async (targetOrganizationId, targetProjectId, targetInvestmentId) => {
    setProjectId(targetProjectId)
    setInvestmentId(targetInvestmentId)
    setOrganizationId(targetOrganizationId)

    setIsLoading(true)
    await goToInvestment(targetOrganizationId, targetProjectId, targetInvestmentId)
  }

  return (
    <Form {...formProps}>
      <Form.Item label={LABEL_CODE} required={true} name="code">
        <Space>
          <Select
            size="large"
            style={{ width: 110 }}
            value={organizationId}
            options={organizationIds}
            onChange={setOrganizationId}
            showSearch={true}
          />
          -
          <Input
            size="large"
            value={projectId}
            onChange={(event) => setProjectId(event.target.value)}
          />
          -
          <Input
            size="large"
            value={investmentId}
            onChange={(event) => setInvestmentId(event.target.value)}
          />
        </Space>
      </Form.Item>

      <Form.Item label={LABEL_SEARCH}>
        <GoToInvestmentSearch
          key={searchKey}
          onSelect={onSelect}
        />
      </Form.Item>

      <Form.Item style={{ marginBottom: W0 }}>
        <Button {...buttonProps}>
          {LABEL_CONTINUE}
        </Button>
      </Form.Item>
    </Form>
  )
}

GoToInvestmentForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default GoToInvestmentForm
