import React from "react"
import PropTypes from "prop-types"
import { Pie, measureTextWidth } from '@ant-design/plots'

import { getCurrencyValue, getFormattedDecimalValue } from "@components/Amount"

import getPieChartConfig from "./helpers/getPieChartConfig"

const LABEL_FUNDED = "Funded"
const LABEL_PENDING = "Pending"
const LABEL_COMMITTED = "Raised"


const FundingDonutChart = ({ statistics }) => {
  const {
    raisedFundingAmount,
    receivedFundingAmount,
    fundedInvestmentsCount,
    partiallyFundedInvestmentsCount,
    overfundedInvestmentsCount,
    notFundedInvestmentsCount,
  } = statistics

  const pendingAmount = getFormattedDecimalValue(raisedFundingAmount, receivedFundingAmount, "minus")

  const sumFundedCount = fundedInvestmentsCount + partiallyFundedInvestmentsCount + overfundedInvestmentsCount

  const data = [
    {
      type: LABEL_PENDING,
      value: pendingAmount,
      countValue: notFundedInvestmentsCount,
    },
    {
      type: LABEL_FUNDED,
      value: receivedFundingAmount,
      countValue: sumFundedCount,
    }
  ]

  const renderStatistic = (containerWidth, text) => {
    const {
      width: textWidth,
      height: textHeight
    } = measureTextWidth(text, { fontSize: 12 })

    const R = containerWidth / 2

    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(R ** 2) / (textWidth / 2 ** 2) + (textHeight ** 2)), 1)
    }

    const textStyleStr = `width:${containerWidth}px;font-size:12px;line-height:${scale < 1 ? 1 : 'inherit'};`
    return `<div style="${textStyleStr}">${text}</div>`
  }

  const config = getPieChartConfig(data)

  config.innerRadius = 0.65
  config.statistic = {
    title: {
      offsetY: -2,
      customHtml: container => {
        const { width } = container.getBoundingClientRect()
        return renderStatistic(width, LABEL_COMMITTED)
      },
    },
    content: {
      offsetY: 2,
      customHtml: container => {
        const { width } = container.getBoundingClientRect()
        const amountFormatted = getCurrencyValue(raisedFundingAmount, 0)
        return renderStatistic(width, amountFormatted)
      },
    },
  }

  return (
    <Pie {...config} />
  )
}

FundingDonutChart.propTypes = {
  statistics: PropTypes.shape({
    raisedFundingAmount: PropTypes.number.isRequired,
    receivedFundingAmount: PropTypes.number.isRequired,
    fundedInvestmentsCount: PropTypes.number.isRequired,
    partiallyFundedInvestmentsCount: PropTypes.number.isRequired,
    overfundedInvestmentsCount: PropTypes.number.isRequired,
    notFundedInvestmentsCount: PropTypes.number.isRequired,
    fundedAmount: PropTypes.number.isRequired,
    partiallyFundedAmount: PropTypes.number.isRequired,
    overfundedAmount: PropTypes.number.isRequired,
  }).isRequired,
}

export default FundingDonutChart
