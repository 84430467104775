import { DomainStatusEnumKeys } from "./shapes/DomainStatusEnum"

const IndexOrganizationsItemSchema = {
  domainStatus: { enum: DomainStatusEnumKeys },
  email: { format: 'email' },
  isEmailVerified: { type: 'boolean' },
  isDisabled: { type: 'boolean' },
  disabledAt: { format: 'date-time' },
  pinpointProjectsDestroyedAt: { format: 'date-time' },
  identitiesDestroyedAt: { format: 'date-time' },
  gatewayDestroyedAt: { format: 'date-time' },
  filesDestroyedAt: { format: 'date-time' },
  dnsRecordsDestroyedAt: { format: 'date-time' },
  cognitoUsersDestroyedAt: { format: 'date-time' },
  cloudfrontDistributionsDestroyedAt: { format: 'date-time' },
  certificatesDestroyedAt: { format: 'date-time' },
  dwollaCustomersDestroyedAt: { format: 'date-time' },
  tableRecordsDestroyedAt: { format: 'date-time' },
  configurationSetsDestroyedAt: { format: 'date-time' },
  destroyedAt: { format: 'date-time' },
  isDestroyed: { type: 'boolean' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexOrganizationsItemSchema
