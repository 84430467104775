import ACTIVITIES_INDEXACTIVITIES_ITEM_SCHEMA from "./activities/IndexActivitiesItemSchema"
import ACTIVITIES_INDEXINVESTORACTIVITIES_ITEM_SCHEMA from "./activities/IndexInvestorActivitiesItemSchema"
import BACKSTAGE_ACCEPTTERMSOFSERVICE_FORM_INPUTS from "./backstage/AcceptTermsOfServiceFormInputs.json"
import BACKSTAGE_ARCHIVEPROJECT_FORM_INPUTS from "./backstage/ArchiveProjectFormInputs.json"
import BACKSTAGE_AUTHORIZEADMINISTRATOR_FORM_INPUTS from "./backstage/AuthorizeAdministratorFormInputs.json"
import BACKSTAGE_AUTHORIZE_FORM_INPUTS from "./backstage/AuthorizeFormInputs.json"
import BACKSTAGE_CLOSEPROJECT_FORM_INPUTS from "./backstage/CloseProjectFormInputs.json"
import BACKSTAGE_CONNECTFUNDPARTNER_FORM_INPUTS from "./backstage/ConnectFundPartnerFormInputs.json"
import BACKSTAGE_CONNECTFUNDPROJECTPROPERTY_FORM_INPUTS from "./backstage/ConnectFundProjectPropertyFormInputs.json"
import BACKSTAGE_CONNECTSPONSORPARTNER_FORM_INPUTS from "./backstage/ConnectSponsorPartnerFormInputs.json"
import BACKSTAGE_CREATEBATCHFILES_FORM_INPUTS from "./backstage/CreateBatchFilesFormInputs.json"
import BACKSTAGE_CREATEBATCHINVESTORFILES_FORM_INPUTS from "./backstage/CreateBatchInvestorFilesFormInputs.json"
import BACKSTAGE_CREATEBROKER_FORM_INPUTS from "./backstage/CreateBrokerFormInputs.json"
import BACKSTAGE_CREATEFILE_FORM_INPUTS from "./backstage/CreateFileFormInputs.json"
import BACKSTAGE_CREATEFUNDMEMBER_FORM_INPUTS from "./backstage/CreateFundMemberFormInputs.json"
import BACKSTAGE_CREATEMEMBER_FORM_INPUTS from "./backstage/CreateMemberFormInputs.json"
import BACKSTAGE_CREATEOPTION_FORM_INPUTS from "./backstage/CreateOptionFormInputs.json"
import BACKSTAGE_CREATEPROJECT_FORM_INPUTS from "./backstage/CreateProjectFormInputs.json"
import BACKSTAGE_CREATEPROPERTY_FORM_INPUTS from "./backstage/CreatePropertyFormInputs.json"
import BACKSTAGE_CREATESPONSOR_FORM_INPUTS from "./backstage/CreateSponsorFormInputs.json"
import BACKSTAGE_CREATEUSERGROUP_FORM_INPUTS from "./backstage/CreateUserGroupFormInputs.json"
import BACKSTAGE_DISABLEUSER_FORM_INPUTS from "./backstage/DisableUserFormInputs.json"
import BACKSTAGE_DISCONNECTFUNDPROJECTPROPERTY_FORM_INPUTS from "./backstage/DisconnectFundProjectPropertyFormInputs.json"
import BACKSTAGE_ENABLEMYUSER2FA_FORM_INPUTS from "./backstage/EnableMyUser2FAFormInputs.json"
import BACKSTAGE_ENABLEUSER_FORM_INPUTS from "./backstage/EnableUserFormInputs.json"
import BACKSTAGE_INDEXBATCHFILES_ITEM_SCHEMA from "./backstage/IndexBatchFilesItemSchema"
import BACKSTAGE_INDEXINVESTORFILES_ITEM_SCHEMA from "./backstage/IndexInvestorFilesItemSchema"
import BACKSTAGE_INDEXMYFILES_ITEM_SCHEMA from "./backstage/IndexMyFilesItemSchema"
import BACKSTAGE_INDEXOPTIONS_ITEM_SCHEMA from "./backstage/IndexOptionsItemSchema"
import BACKSTAGE_INDEXPROJECTBATCHES_ITEM_SCHEMA from "./backstage/IndexProjectBatchesItemSchema"
import BACKSTAGE_INDEXPROPERTIES_ITEM_SCHEMA from "./backstage/IndexPropertiesItemSchema"
import BACKSTAGE_INDEXPUBLISHEDPROJECTS_ITEM_SCHEMA from "./backstage/IndexPublishedProjectsItemSchema"
import BACKSTAGE_INDEXSPONSORS_ITEM_SCHEMA from "./backstage/IndexSponsorsItemSchema"
import BACKSTAGE_INDEXUSERGROUPS_ITEM_SCHEMA from "./backstage/IndexUserGroupsItemSchema"
import BACKSTAGE_INDEXUSERS_ITEM_SCHEMA from "./backstage/IndexUsersItemSchema"
import BACKSTAGE_LISTPROJECTS_ITEM_SCHEMA from "./backstage/ListProjectsItemSchema"
import BACKSTAGE_MAPINVESTMENTDOCUMENTS_FORM_INPUTS from "./backstage/MapInvestmentDocumentsFormInputs.json"
import BACKSTAGE_PUBLISHPROJECT_FORM_INPUTS from "./backstage/PublishProjectFormInputs.json"
import BACKSTAGE_UNPUBLISHPROJECT_FORM_INPUTS from "./backstage/UnpublishProjectFormInputs.json"
import BACKSTAGE_UPDATEBATCH_FORM_INPUTS from "./backstage/UpdateBatchFormInputs.json"
import BACKSTAGE_UPDATEBROKER_FORM_INPUTS from "./backstage/UpdateBrokerFormInputs.json"
import BACKSTAGE_UPDATEFUNDMEMBER_FORM_INPUTS from "./backstage/UpdateFundMemberFormInputs.json"
import BACKSTAGE_UPDATEFUNDPARTNER_FORM_INPUTS from "./backstage/UpdateFundPartnerFormInputs.json"
import BACKSTAGE_UPDATEMEMBER_FORM_INPUTS from "./backstage/UpdateMemberFormInputs.json"
import BACKSTAGE_UPDATEMYUSER_FORM_INPUTS from "./backstage/UpdateMyUserFormInputs.json"
import BACKSTAGE_UPDATEOPTION_FORM_INPUTS from "./backstage/UpdateOptionFormInputs.json"
import BACKSTAGE_UPDATEPARTNERPROJECT_FORM_INPUTS from "./backstage/UpdatePartnerProjectFormInputs.json"
import BACKSTAGE_UPDATEPROJECT_FORM_INPUTS from "./backstage/UpdateProjectFormInputs.json"
import BACKSTAGE_UPDATEPROPERTY_FORM_INPUTS from "./backstage/UpdatePropertyFormInputs.json"
import BACKSTAGE_UPDATESPONSORPARTNER_FORM_INPUTS from "./backstage/UpdateSponsorPartnerFormInputs.json"
import BACKSTAGE_UPDATESPONSOR_FORM_INPUTS from "./backstage/UpdateSponsorFormInputs.json"
import BACKSTAGE_UPDATEUSERGROUP_FORM_INPUTS from "./backstage/UpdateUserGroupFormInputs.json"
import CAMPAIGNS_CLONECAMPAIGN_FORM_INPUTS from "./campaigns/CloneCampaignFormInputs.json"
import CAMPAIGNS_CREATECAMPAIGN_FORM_INPUTS from "./campaigns/CreateCampaignFormInputs.json"
import CAMPAIGNS_INDEXCAMPAIGNS_ITEM_SCHEMA from "./campaigns/IndexCampaignsItemSchema"
import CAMPAIGNS_INDEXRECIPIENTREPORTS_ITEM_SCHEMA from "./campaigns/IndexRecipientReportsItemSchema"
import CAMPAIGNS_LISTRECIPIENTOPTOUTS_ITEM_SCHEMA from "./campaigns/ListRecipientOptOutsItemSchema"
import CAMPAIGNS_PUBLISHCAMPAIGN_FORM_INPUTS from "./campaigns/PublishCampaignFormInputs.json"
import CAMPAIGNS_UNPUBLISHCAMPAIGN_FORM_INPUTS from "./campaigns/UnpublishCampaignFormInputs.json"
import CAMPAIGNS_UPDATECAMPAIGNTAGS_FORM_INPUTS from "./campaigns/UpdateCampaignTagsFormInputs.json"
import CAMPAIGNS_UPDATECAMPAIGN_FORM_INPUTS from "./campaigns/UpdateCampaignFormInputs.json"
import DOCUMENTS_INDEXDOCUSIGNWEBHOOKS_ITEM_SCHEMA from "./documents/IndexDocusignWebhooksItemSchema"
import DOCUMENTS_LISTTEMPLATES_ITEM_SCHEMA from "./documents/ListTemplatesItemSchema"
import IDENTITY_AUTHENTICATEAS_FORM_INPUTS from "./identity/AuthenticateAsFormInputs.json"
import IDENTITY_AUTHENTICATE_FORM_INPUTS from "./identity/AuthenticateFormInputs.json"
import IDENTITY_CHANGEMYIDENTITYEMAIL_FORM_INPUTS from "./identity/ChangeMyIdentityEmailFormInputs.json"
import IDENTITY_CONFIRMMYIDENTITYEMAIL_FORM_INPUTS from "./identity/ConfirmMyIdentityEmailFormInputs.json"
import INVESTMENTS_ACCEPTINVESTMENT_FORM_INPUTS from "./investments/AcceptInvestmentFormInputs.json"
import INVESTMENTS_ACCEPTTERMSOFSERVICE_FORM_INPUTS from "./investments/AcceptTermsOfServiceFormInputs.json"
import INVESTMENTS_AUTHORIZE_FORM_INPUTS from "./investments/AuthorizeFormInputs.json"
import INVESTMENTS_BACKUPINVESTMENT_FORM_INPUTS from "./investments/BackupInvestmentFormInputs.json"
import INVESTMENTS_CONNECTACCOUNTIDENTITY_FORM_INPUTS from "./investments/ConnectAccountIdentityFormInputs.json"
import INVESTMENTS_CREATEACCOUNTINVESTMENT_FORM_INPUTS from "./investments/CreateAccountInvestmentFormInputs.json"
import INVESTMENTS_CREATEACCOUNT_FORM_INPUTS from "./investments/CreateAccountFormInputs.json"
import INVESTMENTS_CREATECOMMITMENT_FORM_INPUTS from "./investments/CreateCommitmentFormInputs.json"
import INVESTMENTS_CREATECUSTODIAN_FORM_INPUTS from "./investments/CreateCustodianFormInputs.json"
import INVESTMENTS_CREATEINVESTMENT_FORM_INPUTS from "./investments/CreateInvestmentFormInputs.json"
import INVESTMENTS_DISABLEACCOUNT2FA_FORM_INPUTS from "./investments/DisableAccount2FAFormInputs.json"
import INVESTMENTS_DISABLEACCOUNT_FORM_INPUTS from "./investments/DisableAccountFormInputs.json"
import INVESTMENTS_DISABLEMYACCOUNT2FA_FORM_INPUTS from "./investments/DisableMyAccount2FAFormInputs.json"
import INVESTMENTS_ENABLEACCOUNT2FA_FORM_INPUTS from "./investments/EnableAccount2FAFormInputs.json"
import INVESTMENTS_ENABLEACCOUNT_FORM_INPUTS from "./investments/EnableAccountFormInputs.json"
import INVESTMENTS_ENABLEMYACCOUNT2FA_FORM_INPUTS from "./investments/EnableMyAccount2FAFormInputs.json"
import INVESTMENTS_IMPORTACCOUNTS_FORM_INPUTS from "./investments/ImportAccountsFormInputs.json"
import INVESTMENTS_IMPORTINVESTMENTS_FORM_INPUTS from "./investments/ImportInvestmentsFormInputs.json"
import INVESTMENTS_INDEXACCOUNTINVESTMENTS_ITEM_SCHEMA from "./investments/IndexAccountInvestmentsItemSchema"
import INVESTMENTS_INDEXACCOUNTS_ITEM_SCHEMA from "./investments/IndexAccountsItemSchema"
import INVESTMENTS_INDEXCUSTODIANS_ITEM_SCHEMA from "./investments/IndexCustodiansItemSchema"
import INVESTMENTS_INDEXINVESTMENTS_ITEM_SCHEMA from "./investments/IndexInvestmentsItemSchema"
import INVESTMENTS_INDEXMYINVESTMENTS_ITEM_SCHEMA from "./investments/IndexMyInvestmentsItemSchema"
import INVESTMENTS_INDEXPROJECTINVESTMENTS_ITEM_SCHEMA from "./investments/IndexProjectInvestmentsItemSchema"
import INVESTMENTS_LISTCUSTODIANS_ITEM_SCHEMA from "./investments/ListCustodiansItemSchema"
import INVESTMENTS_POSTPONECOMMITMENT_FORM_INPUTS from "./investments/PostponeCommitmentFormInputs.json"
import INVESTMENTS_REJECTINVESTMENT_FORM_INPUTS from "./investments/RejectInvestmentFormInputs.json"
import INVESTMENTS_RESETACCOUNTIDENTITY_FORM_INPUTS from "./investments/ResetAccountIdentityFormInputs.json"
import INVESTMENTS_RESETINVESTMENTSUBSCRIPTION_FORM_INPUTS from "./investments/ResetInvestmentSubscriptionFormInputs.json"
import INVESTMENTS_UPDATEACCOUNTNOTE_FORM_INPUTS from "./investments/UpdateAccountNoteFormInputs.json"
import INVESTMENTS_UPDATEACCOUNTTAGS_FORM_INPUTS from "./investments/UpdateAccountTagsFormInputs.json"
import INVESTMENTS_UPDATEACCOUNT_FORM_INPUTS from "./investments/UpdateAccountFormInputs.json"
import INVESTMENTS_UPDATECUSTODIANSOURCES_FORM_INPUTS from "./investments/UpdateCustodianSourcesFormInputs.json"
import INVESTMENTS_UPDATECUSTODIAN_FORM_INPUTS from "./investments/UpdateCustodianFormInputs.json"
import INVESTMENTS_UPDATEINVESTMENTNOTE_FORM_INPUTS from "./investments/UpdateInvestmentNoteFormInputs.json"
import INVESTMENTS_UPDATEINVESTMENTSIGNED_FORM_INPUTS from "./investments/UpdateInvestmentSignedFormInputs.json"
import INVESTMENTS_UPDATEINVESTMENTSOURCES_FORM_INPUTS from "./investments/UpdateInvestmentSourcesFormInputs.json"
import INVESTMENTS_UPDATEINVESTMENT_FORM_INPUTS from "./investments/UpdateInvestmentFormInputs.json"
import INVESTMENTS_UPDATEMYACCOUNT_FORM_INPUTS from "./investments/UpdateMyAccountFormInputs.json"
import INVESTMENTS_UPDATEMYIMMUTABLEACCOUNTINVESTOR_FORM_INPUTS from "./investments/UpdateMyImmutableAccountInvestorFormInputs.json"
import INVESTMENTS_UPDATEMYINVESTMENTFUNDINGSTATUS_FORM_INPUTS from "./investments/UpdateMyInvestmentFundingStatusFormInputs.json"
import INVESTMENTS_UPDATEMYINVESTMENTSIGNURL_FORM_INPUTS from "./investments/UpdateMyInvestmentSignUrlFormInputs.json"
import INVESTMENTS_UPDATEMYINVESTMENTSOURCES_FORM_INPUTS from "./investments/UpdateMyInvestmentSourcesFormInputs.json"
import INVESTMENTS_UPDATEMYINVESTMENTSUBSCRIPTION_FORM_INPUTS from "./investments/UpdateMyInvestmentSubscriptionFormInputs.json"
import INVESTMENTS_UPDATEMYINVESTMENT_FORM_INPUTS from "./investments/UpdateMyInvestmentFormInputs.json"
import NOTIFICATIONS_INDEXNOTIFICATIONS_ITEM_SCHEMA from "./notifications/IndexNotificationsItemSchema"
import NOTIFICATIONS_LISTTEMPLATES_ITEM_SCHEMA from "./notifications/ListTemplatesItemSchema"
import NOTIFICATIONS_RESETTEMPLATE_FORM_INPUTS from "./notifications/ResetTemplateFormInputs.json"
import NOTIFICATIONS_SENDCONTACTUSGUESTNOTIFICATION_FORM_INPUTS from "./notifications/SendContactUsGuestNotificationFormInputs.json"
import NOTIFICATIONS_SENDCONTACTUSINVESTORNOTIFICATION_FORM_INPUTS from "./notifications/SendContactUsInvestorNotificationFormInputs.json"
import NOTIFICATIONS_SENDDISTRIBUTIONPENDINGAPPROVALNOTIFICATION_FORM_INPUTS from "./notifications/SendDistributionPendingApprovalNotificationFormInputs.json"
import NOTIFICATIONS_SENDINVESTMENTPENDINGREFUNDNOTIFICATION_FORM_INPUTS from "./notifications/SendInvestmentPendingRefundNotificationFormInputs.json"
import NOTIFICATIONS_SENDNOTIFICATION_FORM_INPUTS from "./notifications/SendNotificationFormInputs.json"
import NOTIFICATIONS_TOGGLETEMPLATE_FORM_INPUTS from "./notifications/ToggleTemplateFormInputs.json"
import NOTIFICATIONS_UPDATETEMPLATE_FORM_INPUTS from "./notifications/UpdateTemplateFormInputs.json"
import ORGANIZATIONS_CREATEMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS from "./organizations/CreateMyOrganizationEmailIdentityFormInputs.json"
import ORGANIZATIONS_CREATEORGANIZATION_FORM_INPUTS from "./organizations/CreateOrganizationFormInputs.json"
import ORGANIZATIONS_CREATEPARTNERORGANIZATION_FORM_INPUTS from "./organizations/CreatePartnerOrganizationFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONCERTIFICATES_FORM_INPUTS from "./organizations/DestroyOrganizationCertificatesFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONCLOUDFRONTDISTRIBUTION_FORM_INPUTS from "./organizations/DestroyOrganizationCloudFrontDistributionFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONCOGNITOUSERS_FORM_INPUTS from "./organizations/DestroyOrganizationCognitoUsersFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONCONFIGURATIONSETS_FORM_INPUTS from "./organizations/DestroyOrganizationConfigurationSetsFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONDNSRECORDS_FORM_INPUTS from "./organizations/DestroyOrganizationDnsRecordsFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONDWOLLACUSTOMERS_FORM_INPUTS from "./organizations/DestroyOrganizationDwollaCustomersFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONFILES_FORM_INPUTS from "./organizations/DestroyOrganizationFilesFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONGATEWAY_FORM_INPUTS from "./organizations/DestroyOrganizationGatewayFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONIDENTITIES_FORM_INPUTS from "./organizations/DestroyOrganizationIdentitiesFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONPINPOINTPROJECTS_FORM_INPUTS from "./organizations/DestroyOrganizationPinpointProjectsFormInputs.json"
import ORGANIZATIONS_DESTROYORGANIZATIONTABLERECORDS_FORM_INPUTS from "./organizations/DestroyOrganizationTableRecordsFormInputs.json"
import ORGANIZATIONS_DISABLEORGANIZATION_FORM_INPUTS from "./organizations/DisableOrganizationFormInputs.json"
import ORGANIZATIONS_ENABLEORGANIZATION_FORM_INPUTS from "./organizations/EnableOrganizationFormInputs.json"
import ORGANIZATIONS_INDEXORGANIZATIONS_ITEM_SCHEMA from "./organizations/IndexOrganizationsItemSchema"
import ORGANIZATIONS_INDEXPARTNERORGANIZATIONS_ITEM_SCHEMA from "./organizations/IndexPartnerOrganizationsItemSchema"
import ORGANIZATIONS_RESETMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS from "./organizations/ResetMyOrganizationEmailIdentityFormInputs.json"
import ORGANIZATIONS_SETMYORGANIZATIONCONTROLLER_FORM_INPUTS from "./organizations/SetMyOrganizationControllerFormInputs.json"
import ORGANIZATIONS_UPDATEMYORGANIZATIONDOMAINSTATUS_FORM_INPUTS from "./organizations/UpdateMyOrganizationDomainStatusFormInputs.json"
import ORGANIZATIONS_UPDATEMYORGANIZATIONEMAILVERIFICATIONSTATUS_FORM_INPUTS from "./organizations/UpdateMyOrganizationEmailVerificationStatusFormInputs.json"
import ORGANIZATIONS_UPDATEMYORGANIZATION_FORM_INPUTS from "./organizations/UpdateMyOrganizationFormInputs.json"
import ORGANIZATIONS_UPDATEORGANIZATION_FORM_INPUTS from "./organizations/UpdateOrganizationFormInputs.json"
import ORGANIZATIONS_VERIFYMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS from "./organizations/VerifyMyOrganizationEmailIdentityFormInputs.json"
import STORAGE_CREATEUPLOADURLS_FORM_INPUTS from "./storage/CreateUploadUrlsFormInputs.json"
import TRANSACTIONS_APPROVETRANSACTION_FORM_INPUTS from "./transactions/ApproveTransactionFormInputs.json"
import TRANSACTIONS_CERTIFYMYCUSTOMERBENEFICIALOWNERSHIP_FORM_INPUTS from "./transactions/CertifyMyCustomerBeneficialOwnershipFormInputs.json"
import TRANSACTIONS_CONNECTCUSTOMSOURCE_FORM_INPUTS from "./transactions/ConnectCustomSourceFormInputs.json"
import TRANSACTIONS_CONNECTSOURCE_FORM_INPUTS from "./transactions/ConnectSourceFormInputs.json"
import TRANSACTIONS_CREATEBANKACCOUNT_FORM_INPUTS from "./transactions/CreateBankAccountFormInputs.json"
import TRANSACTIONS_CREATEDISTRIBUTIONTRANSACTION_FORM_INPUTS from "./transactions/CreateDistributionTransactionFormInputs.json"
import TRANSACTIONS_CREATEIMPERSONATEDRECEIVEONLYBUSINESSCUSTOMER_FORM_INPUTS from "./transactions/CreateImpersonatedReceiveOnlyBusinessCustomerFormInputs.json"
import TRANSACTIONS_CREATEIMPERSONATEDUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS from "./transactions/CreateImpersonatedUnverifiedBusinessCustomerFormInputs.json"
import TRANSACTIONS_CREATEIMPERSONATEDUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS from "./transactions/CreateImpersonatedUnverifiedPersonalCustomerFormInputs.json"
import TRANSACTIONS_CREATEMYCUSTOMERBENEFICIALOWNER_FORM_INPUTS from "./transactions/CreateMyCustomerBeneficialOwnerFormInputs.json"
import TRANSACTIONS_CREATERECEIVEONLYBUSINESSCUSTOMER_FORM_INPUTS from "./transactions/CreateReceiveOnlyBusinessCustomerFormInputs.json"
import TRANSACTIONS_CREATEUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS from "./transactions/CreateUnverifiedBusinessCustomerFormInputs.json"
import TRANSACTIONS_CREATEUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS from "./transactions/CreateUnverifiedPersonalCustomerFormInputs.json"
import TRANSACTIONS_FUNDINVESTMENT_FORM_INPUTS from "./transactions/FundInvestmentFormInputs.json"
import TRANSACTIONS_INDEXBANKACCOUNTS_ITEM_SCHEMA from "./transactions/IndexBankAccountsItemSchema"
import TRANSACTIONS_INDEXCUSTOMERS_ITEM_SCHEMA from "./transactions/IndexCustomersItemSchema"
import TRANSACTIONS_INDEXFAILEDTRANSACTIONS_ITEM_SCHEMA from "./transactions/IndexFailedTransactionsItemSchema"
import TRANSACTIONS_INDEXINVESTORTRANSACTIONS_ITEM_SCHEMA from "./transactions/IndexInvestorTransactionsItemSchema"
import TRANSACTIONS_INDEXMYTRANSACTIONS_ITEM_SCHEMA from "./transactions/IndexMyTransactionsItemSchema"
import TRANSACTIONS_INDEXTRANSACTIONS_ITEM_SCHEMA from "./transactions/IndexTransactionsItemSchema"
import TRANSACTIONS_LISTMYCUSTOMERBENEFICIALOWNERS_ITEM_SCHEMA from "./transactions/ListMyCustomerBeneficialOwnersItemSchema"
import TRANSACTIONS_LISTSOURCEBANKACCOUNTS_ITEM_SCHEMA from "./transactions/ListSourceBankAccountsItemSchema"
import TRANSACTIONS_REGISTEREXTERNALDISTRIBUTIONTRANSACTION_FORM_INPUTS from "./transactions/RegisterExternalDistributionTransactionFormInputs.json"
import TRANSACTIONS_REGISTEREXTERNALFUNDINGTRANSACTION_FORM_INPUTS from "./transactions/RegisterExternalFundingTransactionFormInputs.json"
import TRANSACTIONS_REJECTTRANSACTION_FORM_INPUTS from "./transactions/RejectTransactionFormInputs.json"
import TRANSACTIONS_RETRYDISTRIBUTIONTRANSACTION_FORM_INPUTS from "./transactions/RetryDistributionTransactionFormInputs.json"
import TRANSACTIONS_SUBMITMYSOURCEMICRODEPOSITS_FORM_INPUTS from "./transactions/SubmitMySourceMicroDepositsFormInputs.json"
import TRANSACTIONS_TRANSFERCONTROLLERCUSTOMERS_FORM_INPUTS from "./transactions/TransferControllerCustomersFormInputs.json"
import TRANSACTIONS_UPDATEMYCUSTOMERBENEFICIALOWNER_FORM_INPUTS from "./transactions/UpdateMyCustomerBeneficialOwnerFormInputs.json"
import TRANSACTIONS_UPDATEMYCUSTOMERVERIFICATIONSTATUS_FORM_INPUTS from "./transactions/UpdateMyCustomerVerificationStatusFormInputs.json"
import TRANSACTIONS_UPDATEMYSOURCEVERIFICATIONSTATUS_FORM_INPUTS from "./transactions/UpdateMySourceVerificationStatusFormInputs.json"
import TRANSACTIONS_UPDATEREJECTEDTRANSACTIONAMOUNT_FORM_INPUTS from "./transactions/UpdateRejectedTransactionAmountFormInputs.json"
import TRANSACTIONS_UPGRADEMYUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS from "./transactions/UpgradeMyUnverifiedBusinessCustomerFormInputs.json"
import TRANSACTIONS_UPGRADEMYUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS from "./transactions/UpgradeMyUnverifiedPersonalCustomerFormInputs.json"
import TRANSACTIONS_UPLOADBUSINESSCUSTOMERDOCUMENTS_FORM_INPUTS from "./transactions/UploadBusinessCustomerDocumentsFormInputs.json"
import TRANSACTIONS_UPLOADMYCUSTOMERBENEFICIALOWNERDOCUMENT_FORM_INPUTS from "./transactions/UploadMyCustomerBeneficialOwnerDocumentFormInputs.json"
import TRANSACTIONS_UPLOADPERSONALCUSTOMERDOCUMENT_FORM_INPUTS from "./transactions/UploadPersonalCustomerDocumentFormInputs.json"
import TRANSACTIONS_VALIDATEDISTRIBUTIONTRANSACTION_FORM_INPUTS from "./transactions/ValidateDistributionTransactionFormInputs.json"
import TRANSACTIONS_VERIFYMYCUSTOMSOURCE_FORM_INPUTS from "./transactions/VerifyMyCustomSourceFormInputs.json"

const components = {
  ACTIVITIES_INDEXACTIVITIES_ITEM_SCHEMA,
  ACTIVITIES_INDEXINVESTORACTIVITIES_ITEM_SCHEMA,
  BACKSTAGE_ACCEPTTERMSOFSERVICE_FORM_INPUTS,
  BACKSTAGE_ARCHIVEPROJECT_FORM_INPUTS,
  BACKSTAGE_AUTHORIZEADMINISTRATOR_FORM_INPUTS,
  BACKSTAGE_AUTHORIZE_FORM_INPUTS,
  BACKSTAGE_CLOSEPROJECT_FORM_INPUTS,
  BACKSTAGE_CONNECTFUNDPARTNER_FORM_INPUTS,
  BACKSTAGE_CONNECTFUNDPROJECTPROPERTY_FORM_INPUTS,
  BACKSTAGE_CONNECTSPONSORPARTNER_FORM_INPUTS,
  BACKSTAGE_CREATEBATCHFILES_FORM_INPUTS,
  BACKSTAGE_CREATEBATCHINVESTORFILES_FORM_INPUTS,
  BACKSTAGE_CREATEBROKER_FORM_INPUTS,
  BACKSTAGE_CREATEFILE_FORM_INPUTS,
  BACKSTAGE_CREATEFUNDMEMBER_FORM_INPUTS,
  BACKSTAGE_CREATEMEMBER_FORM_INPUTS,
  BACKSTAGE_CREATEOPTION_FORM_INPUTS,
  BACKSTAGE_CREATEPROJECT_FORM_INPUTS,
  BACKSTAGE_CREATEPROPERTY_FORM_INPUTS,
  BACKSTAGE_CREATESPONSOR_FORM_INPUTS,
  BACKSTAGE_CREATEUSERGROUP_FORM_INPUTS,
  BACKSTAGE_DISABLEUSER_FORM_INPUTS,
  BACKSTAGE_DISCONNECTFUNDPROJECTPROPERTY_FORM_INPUTS,
  BACKSTAGE_ENABLEMYUSER2FA_FORM_INPUTS,
  BACKSTAGE_ENABLEUSER_FORM_INPUTS,
  BACKSTAGE_INDEXBATCHFILES_ITEM_SCHEMA,
  BACKSTAGE_INDEXINVESTORFILES_ITEM_SCHEMA,
  BACKSTAGE_INDEXMYFILES_ITEM_SCHEMA,
  BACKSTAGE_INDEXOPTIONS_ITEM_SCHEMA,
  BACKSTAGE_INDEXPROJECTBATCHES_ITEM_SCHEMA,
  BACKSTAGE_INDEXPROPERTIES_ITEM_SCHEMA,
  BACKSTAGE_INDEXPUBLISHEDPROJECTS_ITEM_SCHEMA,
  BACKSTAGE_INDEXSPONSORS_ITEM_SCHEMA,
  BACKSTAGE_INDEXUSERGROUPS_ITEM_SCHEMA,
  BACKSTAGE_INDEXUSERS_ITEM_SCHEMA,
  BACKSTAGE_LISTPROJECTS_ITEM_SCHEMA,
  BACKSTAGE_MAPINVESTMENTDOCUMENTS_FORM_INPUTS,
  BACKSTAGE_PUBLISHPROJECT_FORM_INPUTS,
  BACKSTAGE_UNPUBLISHPROJECT_FORM_INPUTS,
  BACKSTAGE_UPDATEBATCH_FORM_INPUTS,
  BACKSTAGE_UPDATEBROKER_FORM_INPUTS,
  BACKSTAGE_UPDATEFUNDMEMBER_FORM_INPUTS,
  BACKSTAGE_UPDATEFUNDPARTNER_FORM_INPUTS,
  BACKSTAGE_UPDATEMEMBER_FORM_INPUTS,
  BACKSTAGE_UPDATEMYUSER_FORM_INPUTS,
  BACKSTAGE_UPDATEOPTION_FORM_INPUTS,
  BACKSTAGE_UPDATEPARTNERPROJECT_FORM_INPUTS,
  BACKSTAGE_UPDATEPROJECT_FORM_INPUTS,
  BACKSTAGE_UPDATEPROPERTY_FORM_INPUTS,
  BACKSTAGE_UPDATESPONSORPARTNER_FORM_INPUTS,
  BACKSTAGE_UPDATESPONSOR_FORM_INPUTS,
  BACKSTAGE_UPDATEUSERGROUP_FORM_INPUTS,
  CAMPAIGNS_CLONECAMPAIGN_FORM_INPUTS,
  CAMPAIGNS_CREATECAMPAIGN_FORM_INPUTS,
  CAMPAIGNS_INDEXCAMPAIGNS_ITEM_SCHEMA,
  CAMPAIGNS_INDEXRECIPIENTREPORTS_ITEM_SCHEMA,
  CAMPAIGNS_LISTRECIPIENTOPTOUTS_ITEM_SCHEMA,
  CAMPAIGNS_PUBLISHCAMPAIGN_FORM_INPUTS,
  CAMPAIGNS_UNPUBLISHCAMPAIGN_FORM_INPUTS,
  CAMPAIGNS_UPDATECAMPAIGNTAGS_FORM_INPUTS,
  CAMPAIGNS_UPDATECAMPAIGN_FORM_INPUTS,
  DOCUMENTS_INDEXDOCUSIGNWEBHOOKS_ITEM_SCHEMA,
  DOCUMENTS_LISTTEMPLATES_ITEM_SCHEMA,
  IDENTITY_AUTHENTICATEAS_FORM_INPUTS,
  IDENTITY_AUTHENTICATE_FORM_INPUTS,
  IDENTITY_CHANGEMYIDENTITYEMAIL_FORM_INPUTS,
  IDENTITY_CONFIRMMYIDENTITYEMAIL_FORM_INPUTS,
  INVESTMENTS_ACCEPTINVESTMENT_FORM_INPUTS,
  INVESTMENTS_ACCEPTTERMSOFSERVICE_FORM_INPUTS,
  INVESTMENTS_AUTHORIZE_FORM_INPUTS,
  INVESTMENTS_BACKUPINVESTMENT_FORM_INPUTS,
  INVESTMENTS_CONNECTACCOUNTIDENTITY_FORM_INPUTS,
  INVESTMENTS_CREATEACCOUNTINVESTMENT_FORM_INPUTS,
  INVESTMENTS_CREATEACCOUNT_FORM_INPUTS,
  INVESTMENTS_CREATECOMMITMENT_FORM_INPUTS,
  INVESTMENTS_CREATECUSTODIAN_FORM_INPUTS,
  INVESTMENTS_CREATEINVESTMENT_FORM_INPUTS,
  INVESTMENTS_DISABLEACCOUNT2FA_FORM_INPUTS,
  INVESTMENTS_DISABLEACCOUNT_FORM_INPUTS,
  INVESTMENTS_DISABLEMYACCOUNT2FA_FORM_INPUTS,
  INVESTMENTS_ENABLEACCOUNT2FA_FORM_INPUTS,
  INVESTMENTS_ENABLEACCOUNT_FORM_INPUTS,
  INVESTMENTS_ENABLEMYACCOUNT2FA_FORM_INPUTS,
  INVESTMENTS_IMPORTACCOUNTS_FORM_INPUTS,
  INVESTMENTS_IMPORTINVESTMENTS_FORM_INPUTS,
  INVESTMENTS_INDEXACCOUNTINVESTMENTS_ITEM_SCHEMA,
  INVESTMENTS_INDEXACCOUNTS_ITEM_SCHEMA,
  INVESTMENTS_INDEXCUSTODIANS_ITEM_SCHEMA,
  INVESTMENTS_INDEXINVESTMENTS_ITEM_SCHEMA,
  INVESTMENTS_INDEXMYINVESTMENTS_ITEM_SCHEMA,
  INVESTMENTS_INDEXPROJECTINVESTMENTS_ITEM_SCHEMA,
  INVESTMENTS_LISTCUSTODIANS_ITEM_SCHEMA,
  INVESTMENTS_POSTPONECOMMITMENT_FORM_INPUTS,
  INVESTMENTS_REJECTINVESTMENT_FORM_INPUTS,
  INVESTMENTS_RESETACCOUNTIDENTITY_FORM_INPUTS,
  INVESTMENTS_RESETINVESTMENTSUBSCRIPTION_FORM_INPUTS,
  INVESTMENTS_UPDATEACCOUNTNOTE_FORM_INPUTS,
  INVESTMENTS_UPDATEACCOUNTTAGS_FORM_INPUTS,
  INVESTMENTS_UPDATEACCOUNT_FORM_INPUTS,
  INVESTMENTS_UPDATECUSTODIANSOURCES_FORM_INPUTS,
  INVESTMENTS_UPDATECUSTODIAN_FORM_INPUTS,
  INVESTMENTS_UPDATEINVESTMENTNOTE_FORM_INPUTS,
  INVESTMENTS_UPDATEINVESTMENTSIGNED_FORM_INPUTS,
  INVESTMENTS_UPDATEINVESTMENTSOURCES_FORM_INPUTS,
  INVESTMENTS_UPDATEINVESTMENT_FORM_INPUTS,
  INVESTMENTS_UPDATEMYACCOUNT_FORM_INPUTS,
  INVESTMENTS_UPDATEMYIMMUTABLEACCOUNTINVESTOR_FORM_INPUTS,
  INVESTMENTS_UPDATEMYINVESTMENTFUNDINGSTATUS_FORM_INPUTS,
  INVESTMENTS_UPDATEMYINVESTMENTSIGNURL_FORM_INPUTS,
  INVESTMENTS_UPDATEMYINVESTMENTSOURCES_FORM_INPUTS,
  INVESTMENTS_UPDATEMYINVESTMENTSUBSCRIPTION_FORM_INPUTS,
  INVESTMENTS_UPDATEMYINVESTMENT_FORM_INPUTS,
  NOTIFICATIONS_INDEXNOTIFICATIONS_ITEM_SCHEMA,
  NOTIFICATIONS_LISTTEMPLATES_ITEM_SCHEMA,
  NOTIFICATIONS_RESETTEMPLATE_FORM_INPUTS,
  NOTIFICATIONS_SENDCONTACTUSGUESTNOTIFICATION_FORM_INPUTS,
  NOTIFICATIONS_SENDCONTACTUSINVESTORNOTIFICATION_FORM_INPUTS,
  NOTIFICATIONS_SENDDISTRIBUTIONPENDINGAPPROVALNOTIFICATION_FORM_INPUTS,
  NOTIFICATIONS_SENDINVESTMENTPENDINGREFUNDNOTIFICATION_FORM_INPUTS,
  NOTIFICATIONS_SENDNOTIFICATION_FORM_INPUTS,
  NOTIFICATIONS_TOGGLETEMPLATE_FORM_INPUTS,
  NOTIFICATIONS_UPDATETEMPLATE_FORM_INPUTS,
  ORGANIZATIONS_CREATEMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS,
  ORGANIZATIONS_CREATEORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_CREATEPARTNERORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONCERTIFICATES_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONCLOUDFRONTDISTRIBUTION_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONCOGNITOUSERS_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONCONFIGURATIONSETS_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONDNSRECORDS_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONDWOLLACUSTOMERS_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONFILES_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONGATEWAY_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONIDENTITIES_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONPINPOINTPROJECTS_FORM_INPUTS,
  ORGANIZATIONS_DESTROYORGANIZATIONTABLERECORDS_FORM_INPUTS,
  ORGANIZATIONS_DISABLEORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_ENABLEORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_INDEXORGANIZATIONS_ITEM_SCHEMA,
  ORGANIZATIONS_INDEXPARTNERORGANIZATIONS_ITEM_SCHEMA,
  ORGANIZATIONS_RESETMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS,
  ORGANIZATIONS_SETMYORGANIZATIONCONTROLLER_FORM_INPUTS,
  ORGANIZATIONS_UPDATEMYORGANIZATIONDOMAINSTATUS_FORM_INPUTS,
  ORGANIZATIONS_UPDATEMYORGANIZATIONEMAILVERIFICATIONSTATUS_FORM_INPUTS,
  ORGANIZATIONS_UPDATEMYORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_UPDATEORGANIZATION_FORM_INPUTS,
  ORGANIZATIONS_VERIFYMYORGANIZATIONEMAILIDENTITY_FORM_INPUTS,
  STORAGE_CREATEUPLOADURLS_FORM_INPUTS,
  TRANSACTIONS_APPROVETRANSACTION_FORM_INPUTS,
  TRANSACTIONS_CERTIFYMYCUSTOMERBENEFICIALOWNERSHIP_FORM_INPUTS,
  TRANSACTIONS_CONNECTCUSTOMSOURCE_FORM_INPUTS,
  TRANSACTIONS_CONNECTSOURCE_FORM_INPUTS,
  TRANSACTIONS_CREATEBANKACCOUNT_FORM_INPUTS,
  TRANSACTIONS_CREATEDISTRIBUTIONTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_CREATEIMPERSONATEDRECEIVEONLYBUSINESSCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_CREATEIMPERSONATEDUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_CREATEIMPERSONATEDUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_CREATEMYCUSTOMERBENEFICIALOWNER_FORM_INPUTS,
  TRANSACTIONS_CREATERECEIVEONLYBUSINESSCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_CREATEUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_CREATEUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_FUNDINVESTMENT_FORM_INPUTS,
  TRANSACTIONS_INDEXBANKACCOUNTS_ITEM_SCHEMA,
  TRANSACTIONS_INDEXCUSTOMERS_ITEM_SCHEMA,
  TRANSACTIONS_INDEXFAILEDTRANSACTIONS_ITEM_SCHEMA,
  TRANSACTIONS_INDEXINVESTORTRANSACTIONS_ITEM_SCHEMA,
  TRANSACTIONS_INDEXMYTRANSACTIONS_ITEM_SCHEMA,
  TRANSACTIONS_INDEXTRANSACTIONS_ITEM_SCHEMA,
  TRANSACTIONS_LISTMYCUSTOMERBENEFICIALOWNERS_ITEM_SCHEMA,
  TRANSACTIONS_LISTSOURCEBANKACCOUNTS_ITEM_SCHEMA,
  TRANSACTIONS_REGISTEREXTERNALDISTRIBUTIONTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_REGISTEREXTERNALFUNDINGTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_REJECTTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_RETRYDISTRIBUTIONTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_SUBMITMYSOURCEMICRODEPOSITS_FORM_INPUTS,
  TRANSACTIONS_TRANSFERCONTROLLERCUSTOMERS_FORM_INPUTS,
  TRANSACTIONS_UPDATEMYCUSTOMERBENEFICIALOWNER_FORM_INPUTS,
  TRANSACTIONS_UPDATEMYCUSTOMERVERIFICATIONSTATUS_FORM_INPUTS,
  TRANSACTIONS_UPDATEMYSOURCEVERIFICATIONSTATUS_FORM_INPUTS,
  TRANSACTIONS_UPDATEREJECTEDTRANSACTIONAMOUNT_FORM_INPUTS,
  TRANSACTIONS_UPGRADEMYUNVERIFIEDBUSINESSCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_UPGRADEMYUNVERIFIEDPERSONALCUSTOMER_FORM_INPUTS,
  TRANSACTIONS_UPLOADBUSINESSCUSTOMERDOCUMENTS_FORM_INPUTS,
  TRANSACTIONS_UPLOADMYCUSTOMERBENEFICIALOWNERDOCUMENT_FORM_INPUTS,
  TRANSACTIONS_UPLOADPERSONALCUSTOMERDOCUMENT_FORM_INPUTS,
  TRANSACTIONS_VALIDATEDISTRIBUTIONTRANSACTION_FORM_INPUTS,
  TRANSACTIONS_VERIFYMYCUSTOMSOURCE_FORM_INPUTS
}

export default components
