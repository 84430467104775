import React from "react"
import { Descriptions } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { ReadActivityOutputShape } from "@api/services/activities"


const ActivityHeaderDescriptions = ({
  activity,
}) => {
  const items = [
    {
      key: 'operationId',
      label: 'Operation',
      children: activity.operationId,
    },
    ...(activity.investorAccountId
      ? [
          {
            key: 'investorAccountId',
            label: 'Investor',
            children: <Identity id={activity.investorAccountId} />,
          }
        ]
      : []
    ),
    {
      key: 'createdAt',
      label: 'Created At',
      children: <Value value={activity.createdAt} />,
    },
    {
      key: 'userId',
      label: 'Created By',
      children: (
        <Identity
          id={activity.userId}
          shouldFallbackToId={true}
        />
      )
    },
  ]

  return (
    <Descriptions
      size="small"
      items={items}
      column={1}
      bordered={true}
    />
  )
}

ActivityHeaderDescriptions.propTypes = {
  activity: ReadActivityOutputShape.isRequired,
}

export default ActivityHeaderDescriptions
