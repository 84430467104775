import React, { useState } from "react"
import PropTypes from "prop-types"
import { AutoComplete, Input, Space, Typography } from "antd"

import { useAppContext } from "@components/AppContext"
import { getCurrencyValue } from "@components/Amount"
import { INDIVIDUAL, JOINT } from "@api/services/investments/shapes/ProfileTypeEnum"
import { searchInvestmentsOperation } from "@api/services/investments"

const OPTIONS_WIDTH = 600
const MIN_KEYWORD_SIZE = 3

const LABEL_PLACEHOLDER = "Search by keyword..."


const GoToInvestmentSearch = ({ onSelect }) => {
  const [ options, setOptions ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)

  const { request, identity } = useAppContext()

  const getOrganizationName = id =>
    identity.organizations.find(organization => organization.id === id)?.name

  const getInvestmentName = (searchResultItem) => {
    const { investor, name, profileType } = searchResultItem

    const isIndividual = [ JOINT, INDIVIDUAL ].includes(profileType)

    if (isIndividual) {
      return name
    }

    const { givenName, familyName } = investor
    const investorFullName = [ givenName, familyName ].join(' ')

    return `${investorFullName} - ${name}`
  }

  const renderLabel = searchResultItem => {
    const { partition, totalAmount, isFunded } = searchResultItem

    const [ amount ] = getCurrencyValue(totalAmount).split('.')
    const investmentName = getInvestmentName(searchResultItem)
    const organizationName = getOrganizationName(partition)

    const titleItems = [ investmentName, amount ]

    if (isFunded) {
      titleItems.push('Funded')
    }

    const title = titleItems.join(' / ')

    return (
      <Space direction="vertical" size={0}>
        <Typography.Text>{title}</Typography.Text>
        <Typography.Text type="secondary">{organizationName}</Typography.Text>
      </Space>
    )
  }

  const updateOptions = searchResultItems => {
    const updatedOptions = searchResultItems
      .map(item => ({
        label: renderLabel(item),
        value: `${item.partition}-${item.projectId}-${item.id}`,
      }))

    setOptions(updatedOptions)
  }

  const onSearch = (keyword = "") => {
    const shouldSkip = keyword.length < MIN_KEYWORD_SIZE

    if (shouldSkip) {
      return
    }

    setIsLoading(true)

    request(searchInvestmentsOperation, { keyword })
      .then(({ data }) => updateOptions(data))
      .then(() => setIsLoading(false))
  }

  const onClear = () => {
    setOptions([])
  }

  const onChange = (event) => {
    const { value = "" } = event.target
    const shouldClearOptions = value.length < MIN_KEYWORD_SIZE

    if (shouldClearOptions) {
      onClear()
    }
  }

  const onItemSelect = value => {
    const [ organizationId, projectId, investmentId ] = value.split('-')

    return onSelect(organizationId, projectId, investmentId)
  }

  const autoCompleteProps = {
    open: true,
    style: { width: "100%" },
    onSelect: onItemSelect,
    popupMatchSelectWidth: OPTIONS_WIDTH,
    options,
  }

  const inputSearchProps = {
    loading: isLoading,
    allowClear: true,
    placeholder: LABEL_PLACEHOLDER,
    onClear,
    onChange,
    onSearch,
  }

  return (
    <AutoComplete {...autoCompleteProps}>
      <Input.Search {...inputSearchProps} />
    </AutoComplete>
  )
}

GoToInvestmentSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default GoToInvestmentSearch
