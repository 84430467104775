import renderTemplate from "./renderTemplate"


const getExportColumns = backstageContext => {
  const columns = [
    {
      title: "User ID",
      value: "userId",
    },
    {
      title: "Operation",
      value: "operationId",
    },
    {
      title: "Summary",
      value: ({ template }) =>
        renderTemplate(backstageContext, template)
    },
    {
      title: "Created At",
      value: "createdAt",
    }
  ]

  return columns
}

export default getExportColumns
