import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { App, Button } from "antd"
import { CheckOutlined, ExclamationCircleFilled } from "@ant-design/icons"

import { FormShape } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { publishOperation, unpublishOperation } from "@components/Store/BackstageStore/useCampaignsStore"

const LABEL_UNPUBLISH = "Unpublish"
const LABEL_CONFIRM_UNPUBLISH_TITLE = "Unpublish Campaign"
const LABEL_CONFIRM_UNPUBLISH_CONTENT = `The campaign is going to be halted.
Investors who haven't received notifications yet won't receive them.`

const LABEL_PUBLISH = "Publish"
const LABEL_CONFIRM_PUBLISH_TITLE = "Publish Campaign"
const LABEL_CONFIRM_PUBLISH_CONTENT = `Please confirm the publication of the
campaign. Investors will begin receiving notifications [OPTION].`
const LABEL_ALT_SCHEDULED = 'at a scheduled time'
const LABEL_ALT_IMMEDIATELY = 'immediatelly'
const LABEL_ALT_SCHEDULED_ZONE = 'according to their time zone at a scheduled time'
const LABEL_IMMEDIATELY = "Immediatelly"
const LABEL_SCHEDULED = "Scheduled"

const PUBLISHED_STATUS = "PUBLISHED"

const EXPECTED_PUBLISH_ERROR = "UnprocessibleConditionError"


const CampaignPublishButton = ({ form, campaign, onSuccess, saveCampaign }) => {
  const { modal } = App.useApp()
  const {
    request,
    showErrorMessage,
    showSuccessMessage
  } = useAppContext()

  const [ isLoading, setIsLoading ] = useState(false)

  const { status } = campaign

  const isPublished = status === PUBLISHED_STATUS

  const isPublishDisabled = useMemo(() => {
    if (isPublished) {
      return false
    }

    const {
      name = "",
      html = "",
      subject = "",
      fromAddress = "",
      scheduledAt = "",
      subscriptionTypeId = "",
      recipientAccountIds = [],
      shouldSendImmediately = true,
    } = form.getFieldsValue()

    const requiredFieldsMap = {
      name,
      html,
      subject,
      fromAddress,
      subscriptionTypeId,
      recipientAccountIds,
    }

    for (const value of Object.values(requiredFieldsMap)) {
      const isFill = value && value.length > 0

      if (!isFill) {
        return true
      }
    }

    if (shouldSendImmediately) {
      return false
    }

    const isFillScheduledAt = scheduledAt && scheduledAt.length > 0

    return !isFillScheduledAt
  }, [ form, isPublished ])

  const { id, name } = campaign

  const publishCampaign = async () => {
    await request(publishOperation, { id }, [EXPECTED_PUBLISH_ERROR])
      .then(async ({ data: publishedCampaign }) => {
        const shouldClose = true
        await onSuccess(publishedCampaign, shouldClose)
      })
      .then(() => {
        const message = `${name} campaign is published`
        showSuccessMessage(message)
      })
      .catch(error => showErrorMessage(error.originalError.message))
  }

  const unpublishCampaign = async () => {
    const unpublishedCampaign = await request(unpublishOperation, { id })
      .then(({ data }) => data)

    const shouldClose = false
    await onSuccess(unpublishedCampaign, shouldClose)

    const message = `${name} campaign is unpublished`
    showSuccessMessage(message)
  }

  const onClick = async () => {
    if (isPublished) {
      modal.confirm({
        icon: <ExclamationCircleFilled />,
        onOk: () => unpublishCampaign(),
        title: LABEL_CONFIRM_UNPUBLISH_TITLE,
        okText: LABEL_UNPUBLISH,
        okType: "danger",
        content: LABEL_CONFIRM_UNPUBLISH_CONTENT,
      })

      return
    }

    setIsLoading(true)

    const isSaved = await saveCampaign()
    setIsLoading(false)

    if (!isSaved) {
      return
    }

    const {
      shouldSendImmediately = true,
      shouldUseRecipientTimeZone = false
    } = form.getFieldsValue()

    const okText = [
      LABEL_PUBLISH,
      (shouldSendImmediately ? LABEL_IMMEDIATELY : LABEL_SCHEDULED)
    ].join(" ")

    const labelScheduled = shouldUseRecipientTimeZone
      ? LABEL_ALT_SCHEDULED_ZONE
      : LABEL_ALT_SCHEDULED

    const content = LABEL_CONFIRM_PUBLISH_CONTENT
      .replace('[OPTION]', (shouldSendImmediately
        ? LABEL_ALT_IMMEDIATELY
        : labelScheduled
      ))

    modal.confirm({
      icon: <ExclamationCircleFilled />,
      onOk: () => publishCampaign(),
      title: LABEL_CONFIRM_PUBLISH_TITLE,
      okText,
      content,
    })
  }

  const style = { width: "100%" }

  const buttonProps = {
    icon: <CheckOutlined />,
    size: "large",
    type: "primary",
    shape: "round",
    loading: isLoading,
    disabled: isPublishDisabled,
    style,
    onClick,
  }

  if (isPublished) {
    buttonProps.icon = null
    buttonProps.type = "dashed"
    buttonProps.danger = true
  }

  const title = isPublished
    ? LABEL_UNPUBLISH
    : LABEL_PUBLISH

  return (
    <Button {...buttonProps}>
      {title}
    </Button>
  )
}

CampaignPublishButton.propTypes = {
  form: FormShape.isRequired,
  campaign: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired,
}

export default CampaignPublishButton
