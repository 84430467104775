import PropTypes from "prop-types"

import { TemplateCategoryEnumKeys } from "./shapes/TemplateCategoryEnum"
import { TemplateKeyEnumKeys } from "./shapes/TemplateKeyEnum"
import { TemplateTypeEnumKeys } from "./shapes/TemplateTypeEnum"

const properties = {
  category: PropTypes.oneOf(TemplateCategoryEnumKeys).isRequired,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  description: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  key: PropTypes.oneOf(TemplateKeyEnumKeys).isRequired,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  sendAfterHoursCount: PropTypes.number,
  sendTime: PropTypes.string,
  shouldCCOrganization: PropTypes.bool.isRequired,
  shouldCCPlatformSupport: PropTypes.bool,
  source: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  type: PropTypes.oneOf(TemplateTypeEnumKeys).isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.string)
}

const ToggleTemplateOutputShapeShape = PropTypes.exact(properties)

export default ToggleTemplateOutputShapeShape
export { properties }
