import { getConfig } from "@components/Config"
import {
  destroyOrganizationFilesOperation,
  destroyOrganizationGatewayOperation,
  destroyOrganizationDnsRecordsOperation,
  destroyOrganizationIdentitiesOperation,
  destroyOrganizationCertificatesOperation,
  destroyOrganizationTableRecordsOperation,
  destroyOrganizationCognitoUsersOperation,
  destroyOrganizationDwollaCustomersOperation,
  destroyOrganizationPinpointProjectsOperation,
  destroyOrganizationConfigurationSetsOperation,
  destroyOrganizationCloudFrontDistributionOperation,
} from "@api/services/organizations"

const PLATFORM_SHORT_NAME = getConfig("PLATFORM_SHORT_NAME")

const config = [
  {
    title: "Pinpoint Projects",
    operation: destroyOrganizationPinpointProjectsOperation,
    description: "All marketing campaigns",
    timestampFieldName: "pinpointProjectsDestroyedAt",
  },
  {
    title: "Configuration Sets",
    operation: destroyOrganizationConfigurationSetsOperation,
    description: `${PLATFORM_SHORT_NAME} configurations sets`,
    timestampFieldName: "configurationSetsDestroyedAt",
  },
  {
    title: "SES Identities",
    operation: destroyOrganizationIdentitiesOperation,
    description: "Custom domain to send campaigns from",
    timestampFieldName: "identitiesDestroyedAt",
  },
  {
    title: "API Gateway",
    operation: destroyOrganizationGatewayOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain configuration`,
    timestampFieldName: "gatewayDestroyedAt",
  },
  {
    title: "CloudFront Distributions",
    operation: destroyOrganizationCloudFrontDistributionOperation,
    description: "Workaround for HTTP to HTTPS redirect",
    timestampFieldName: "cloudfrontDistributionsDestroyedAt",
  },
  {
    title: "Route53 Records",
    operation: destroyOrganizationDnsRecordsOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain DNS records`,
    timestampFieldName: "dnsRecordsDestroyedAt",
  },
  {
    title: "SSL Certificates",
    operation: destroyOrganizationCertificatesOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain certificates`,
    timestampFieldName: "certificatesDestroyedAt",
  },
  {
    title: "Cognito Users",
    operation: destroyOrganizationCognitoUsersOperation,
    description: "Single organization access users",
    timestampFieldName: "cognitoUsersDestroyedAt",
  },
  {
    title: "Dwolla Customers",
    operation: destroyOrganizationDwollaCustomersOperation,
    description: "Suspend Dwolla customers for single organization access users",
    timestampFieldName: "dwollaCustomersDestroyedAt",
  },
  {
    title: "S3 Files",
    operation: destroyOrganizationFilesOperation,
    description: "All private and public files",
    timestampFieldName: "filesDestroyedAt",
  },
  {
    title: "Table Records",
    operation: destroyOrganizationTableRecordsOperation,
    description: "All table records",
    timestampFieldName: "tableRecordsDestroyedAt",
  }
]

export default config
