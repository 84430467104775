import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { EditOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useOutletContext } from "react-router-dom"

import { Drawer } from "@components/Page"
import { DRAWER_WIDTH2 } from "@components"
import { useAppContext } from "@components/AppContext"
import {
  readOrganizationMetricsOperation
} from "@api/services/organizations"

import OrganizationDestroyModal from "../OrganizationDestroyModal"
import OrganizationUpdateDrawer from "../OrganizationUpdateDrawer"
import OrganizationDescriptions from "./OrganizationDescriptions"

import Title from "./Title"

const LABEL_EDIT = "Edit"


const OrganizationDrawer = ({ table, drawer }) => {
  const updateDrawer = useRef({})
  const destroyModal = useRef({})

  const { updateOrganization } = useOutletContext()
  const { authenticatedRequest, authorizeOrganizationIdentity } = useAppContext()

  const [ organization, setOrganization ] = useState()
  const [ isLoading, setIsLoading ] = useState()
  const [ metrics, setMetrics ] = useState()

  const onClose = () =>
    setOrganization()

  const onOpen = async (targetOrganization) => {
    setIsLoading(true)

    const { id: organizationId } = targetOrganization

    setOrganization(targetOrganization)

    authenticatedRequest(readOrganizationMetricsOperation, { organizationId })
      .then(({ data }) => setMetrics(data))
      .then(() => setIsLoading(false))
  }

  const openUpdateDrawer = () =>
    updateDrawer.current.open(organization)

  const openDestroyModal = () =>
    destroyModal.current.open()

  const extra = (
    <Button
      type="text"
      size="small"
      icon={<EditOutlined />}
      onClick={openUpdateDrawer}
    >
      {LABEL_EDIT}
    </Button>
  )

  const title = organization
    ? (
        <Title
          table={table}
          organization={organization}
          openDestroyModal={openDestroyModal}
          setOrganization={setOrganization}
        />
      )
    : ''

  const drawerProps = {
    title,
    extra,
    drawer,
    onOpen,
    onClose,
    width: DRAWER_WIDTH2,
    loading: isLoading
  }

  const onUpdateSuccess = updatedOrganization => {
    updateOrganization(updatedOrganization)
    return authorizeOrganizationIdentity()
  }

  return (
    <Drawer {...drawerProps}>
      {
        organization && (
          <OrganizationDescriptions
            organization={organization}
            metrics={metrics}
          />
        )
      }

      {
        organization && (
          <OrganizationDestroyModal
            modal={destroyModal}
            organization={organization}
          />
        )
      }

      <OrganizationUpdateDrawer
        drawer={updateDrawer}
        onSuccess={onUpdateSuccess}
      />
    </Drawer>
  )
}

OrganizationDrawer.propTypes = {
  table: PropTypes.shape().isRequired,
  drawer: PropTypes.shape({}).isRequired,
}

export default OrganizationDrawer
