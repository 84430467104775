import capitalize from "lodash.capitalize"

export const BACKSTAGE_CONTACT_US_GUEST = "BACKSTAGE_CONTACT_US_GUEST"
export const BACKSTAGE_CONTACT_US_INVESTOR = "BACKSTAGE_CONTACT_US_INVESTOR"
export const BACKSTAGE_DISTRIBUTION_PENDING_APPROVAL = "BACKSTAGE_DISTRIBUTION_PENDING_APPROVAL"
export const BACKSTAGE_INVESTMENT_REFUND = "BACKSTAGE_INVESTMENT_REFUND"
export const DWOLLA_CUSTOMER_CREATED = "DWOLLA_CUSTOMER_CREATED"
export const DWOLLA_CUSTOMER_REVERIFICATION_NEEDED = "DWOLLA_CUSTOMER_REVERIFICATION_NEEDED"
export const DWOLLA_CUSTOMER_SUSPENDED = "DWOLLA_CUSTOMER_SUSPENDED"
export const DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_APPROVED = "DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_APPROVED"
export const DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_FAILED = "DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_FAILED"
export const DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_NEEDED = "DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_NEEDED"
export const DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_UPLOADED = "DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_UPLOADED"
export const DWOLLA_CUSTOMER_VERIFIED = "DWOLLA_CUSTOMER_VERIFIED"
export const DWOLLA_FUNDING_SOURCE_ADDED = "DWOLLA_FUNDING_SOURCE_ADDED"
export const DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_ADDED = "DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_ADDED"
export const DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_COMPLETED = "DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_COMPLETED"
export const DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_FAILED = "DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_FAILED"
export const DWOLLA_FUNDING_SOURCE_REMOVED = "DWOLLA_FUNDING_SOURCE_REMOVED"
export const DWOLLA_FUNDING_SOURCE_VERIFIED = "DWOLLA_FUNDING_SOURCE_VERIFIED"
export const DWOLLA_INVALID_SOURCE = "DWOLLA_INVALID_SOURCE"
export const DWOLLA_RECEIVER_TRANSFER_CANCELLED = "DWOLLA_RECEIVER_TRANSFER_CANCELLED"
export const DWOLLA_RECEIVER_TRANSFER_COMPLETED = "DWOLLA_RECEIVER_TRANSFER_COMPLETED"
export const DWOLLA_RECEIVER_TRANSFER_CREATED = "DWOLLA_RECEIVER_TRANSFER_CREATED"
export const DWOLLA_RECEIVER_TRANSFER_FAILED = "DWOLLA_RECEIVER_TRANSFER_FAILED"
export const DWOLLA_SENDER_TRANSFER_CANCELLED = "DWOLLA_SENDER_TRANSFER_CANCELLED"
export const DWOLLA_SENDER_TRANSFER_COMPLETED = "DWOLLA_SENDER_TRANSFER_COMPLETED"
export const DWOLLA_SENDER_TRANSFER_CREATED = "DWOLLA_SENDER_TRANSFER_CREATED"
export const DWOLLA_SENDER_TRANSFER_FAILED = "DWOLLA_SENDER_TRANSFER_FAILED"
export const INVESTMENT_COMPLETE = "INVESTMENT_COMPLETE"
export const INVESTMENT_CREATED = "INVESTMENT_CREATED"
export const INVESTMENT_FUNDED = "INVESTMENT_FUNDED"
export const INVESTMENT_NOT_COMPLETE = "INVESTMENT_NOT_COMPLETE"
export const INVESTMENT_OVERFUNDED = "INVESTMENT_OVERFUNDED"
export const INVESTMENT_PARTIALLY_FUNDED = "INVESTMENT_PARTIALLY_FUNDED"
export const INVESTMENT_READY_TO_SIGN = "INVESTMENT_READY_TO_SIGN"
export const INVESTMENT_RECEIVER_SOURCE_CHANGED = "INVESTMENT_RECEIVER_SOURCE_CHANGED"
export const INVESTMENT_REJECTED_DEADLINE_PASSED = "INVESTMENT_REJECTED_DEADLINE_PASSED"
export const INVESTMENT_REJECTED_NOT_INTERESTED = "INVESTMENT_REJECTED_NOT_INTERESTED"
export const INVESTMENT_REJECTED_NO_ACCREDITATION = "INVESTMENT_REJECTED_NO_ACCREDITATION"
export const INVESTMENT_SIGNED = "INVESTMENT_SIGNED"
export const INVESTOR_ACCOUNT_CONNECTED = "INVESTOR_ACCOUNT_CONNECTED"
export const INVESTOR_FILE_CREATED = "INVESTOR_FILE_CREATED"
export const INVESTOR_PASSWORD_RESET = "INVESTOR_PASSWORD_RESET"
export const PROJECT_CLOSED_NON_FUND = "PROJECT_CLOSED_NON_FUND"
export const PROJECT_FILE_CREATED = "PROJECT_FILE_CREATED"

const TemplateKeyEnum = {
  [BACKSTAGE_CONTACT_US_GUEST]: BACKSTAGE_CONTACT_US_GUEST,
  [BACKSTAGE_CONTACT_US_INVESTOR]: BACKSTAGE_CONTACT_US_INVESTOR,
  [BACKSTAGE_DISTRIBUTION_PENDING_APPROVAL]: BACKSTAGE_DISTRIBUTION_PENDING_APPROVAL,
  [BACKSTAGE_INVESTMENT_REFUND]: BACKSTAGE_INVESTMENT_REFUND,
  [DWOLLA_CUSTOMER_CREATED]: DWOLLA_CUSTOMER_CREATED,
  [DWOLLA_CUSTOMER_REVERIFICATION_NEEDED]: DWOLLA_CUSTOMER_REVERIFICATION_NEEDED,
  [DWOLLA_CUSTOMER_SUSPENDED]: DWOLLA_CUSTOMER_SUSPENDED,
  [DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_APPROVED]: DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_APPROVED,
  [DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_FAILED]: DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_FAILED,
  [DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_NEEDED]: DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_NEEDED,
  [DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_UPLOADED]: DWOLLA_CUSTOMER_VERIFICATION_DOCUMENT_UPLOADED,
  [DWOLLA_CUSTOMER_VERIFIED]: DWOLLA_CUSTOMER_VERIFIED,
  [DWOLLA_FUNDING_SOURCE_ADDED]: DWOLLA_FUNDING_SOURCE_ADDED,
  [DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_ADDED]: DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_ADDED,
  [DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_COMPLETED]: DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_COMPLETED,
  [DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_FAILED]: DWOLLA_FUNDING_SOURCE_MICRODEPOSITS_FAILED,
  [DWOLLA_FUNDING_SOURCE_REMOVED]: DWOLLA_FUNDING_SOURCE_REMOVED,
  [DWOLLA_FUNDING_SOURCE_VERIFIED]: DWOLLA_FUNDING_SOURCE_VERIFIED,
  [DWOLLA_INVALID_SOURCE]: DWOLLA_INVALID_SOURCE,
  [DWOLLA_RECEIVER_TRANSFER_CANCELLED]: DWOLLA_RECEIVER_TRANSFER_CANCELLED,
  [DWOLLA_RECEIVER_TRANSFER_COMPLETED]: DWOLLA_RECEIVER_TRANSFER_COMPLETED,
  [DWOLLA_RECEIVER_TRANSFER_CREATED]: DWOLLA_RECEIVER_TRANSFER_CREATED,
  [DWOLLA_RECEIVER_TRANSFER_FAILED]: DWOLLA_RECEIVER_TRANSFER_FAILED,
  [DWOLLA_SENDER_TRANSFER_CANCELLED]: DWOLLA_SENDER_TRANSFER_CANCELLED,
  [DWOLLA_SENDER_TRANSFER_COMPLETED]: DWOLLA_SENDER_TRANSFER_COMPLETED,
  [DWOLLA_SENDER_TRANSFER_CREATED]: DWOLLA_SENDER_TRANSFER_CREATED,
  [DWOLLA_SENDER_TRANSFER_FAILED]: DWOLLA_SENDER_TRANSFER_FAILED,
  [INVESTMENT_COMPLETE]: INVESTMENT_COMPLETE,
  [INVESTMENT_CREATED]: INVESTMENT_CREATED,
  [INVESTMENT_FUNDED]: INVESTMENT_FUNDED,
  [INVESTMENT_NOT_COMPLETE]: INVESTMENT_NOT_COMPLETE,
  [INVESTMENT_OVERFUNDED]: INVESTMENT_OVERFUNDED,
  [INVESTMENT_PARTIALLY_FUNDED]: INVESTMENT_PARTIALLY_FUNDED,
  [INVESTMENT_READY_TO_SIGN]: INVESTMENT_READY_TO_SIGN,
  [INVESTMENT_RECEIVER_SOURCE_CHANGED]: INVESTMENT_RECEIVER_SOURCE_CHANGED,
  [INVESTMENT_REJECTED_DEADLINE_PASSED]: INVESTMENT_REJECTED_DEADLINE_PASSED,
  [INVESTMENT_REJECTED_NOT_INTERESTED]: INVESTMENT_REJECTED_NOT_INTERESTED,
  [INVESTMENT_REJECTED_NO_ACCREDITATION]: INVESTMENT_REJECTED_NO_ACCREDITATION,
  [INVESTMENT_SIGNED]: INVESTMENT_SIGNED,
  [INVESTOR_ACCOUNT_CONNECTED]: INVESTOR_ACCOUNT_CONNECTED,
  [INVESTOR_FILE_CREATED]: INVESTOR_FILE_CREATED,
  [INVESTOR_PASSWORD_RESET]: INVESTOR_PASSWORD_RESET,
  [PROJECT_CLOSED_NON_FUND]: PROJECT_CLOSED_NON_FUND,
  [PROJECT_FILE_CREATED]: PROJECT_FILE_CREATED
}

const TemplateKeyEnumKeys = Object.keys(TemplateKeyEnum)

const TemplateKeyEnumOptions = TemplateKeyEnumKeys
  .map(value => ({
    label: capitalize(value),
    value
  }))

export {
  TemplateKeyEnumKeys,
  TemplateKeyEnumOptions
}

export default TemplateKeyEnum
