const HTTP_METHODS = {
  "activities/IndexActivities": "GET",
  "activities/IndexInvestorActivities": "GET",
  "activities/ReadActivity": "GET",
  "backstage/AcceptTermsOfService": "PATCH",
  "backstage/ArchiveProject": "PATCH",
  "backstage/Authorize": "PATCH",
  "backstage/AuthorizeAdministrator": "PATCH",
  "backstage/CloseProject": "PATCH",
  "backstage/ConnectFundPartner": "POST",
  "backstage/ConnectFundProjectProperty": "PATCH",
  "backstage/ConnectSponsorPartner": "POST",
  "backstage/CreateBatchFiles": "POST",
  "backstage/CreateBatchInvestorFiles": "POST",
  "backstage/CreateBroker": "POST",
  "backstage/CreateFile": "POST",
  "backstage/CreateFundMember": "POST",
  "backstage/CreateMember": "POST",
  "backstage/CreateOption": "POST",
  "backstage/CreateProject": "POST",
  "backstage/CreateProperty": "POST",
  "backstage/CreateSponsor": "POST",
  "backstage/CreateUserGroup": "POST",
  "backstage/DeleteBatch": "DELETE",
  "backstage/DeleteFile": "DELETE",
  "backstage/DeleteOption": "DELETE",
  "backstage/DeleteProject": "DELETE",
  "backstage/DeleteProperty": "DELETE",
  "backstage/DeleteSponsor": "DELETE",
  "backstage/DeleteUserGroup": "DELETE",
  "backstage/DisableUser": "PATCH",
  "backstage/DisconnectFundProjectProperty": "PATCH",
  "backstage/DisconnectPartner": "DELETE",
  "backstage/EnableMyUser2FA": "PATCH",
  "backstage/EnableUser": "PATCH",
  "backstage/IndexBatchFiles": "GET",
  "backstage/IndexInvestorFiles": "GET",
  "backstage/IndexMyFiles": "GET",
  "backstage/IndexOptions": "GET",
  "backstage/IndexProjectBatches": "GET",
  "backstage/IndexProperties": "GET",
  "backstage/IndexPublishedProjects": "GET",
  "backstage/IndexSponsors": "GET",
  "backstage/IndexUserGroups": "GET",
  "backstage/IndexUsers": "GET",
  "backstage/ListProjects": "GET",
  "backstage/MapInvestmentDocuments": "POST",
  "backstage/PublishProject": "PATCH",
  "backstage/ReadBatch": "GET",
  "backstage/ReadOption": "GET",
  "backstage/ReadProject": "GET",
  "backstage/ReadProperty": "GET",
  "backstage/ReadPublicPublishedProject": "GET",
  "backstage/ReadSponsor": "GET",
  "backstage/ReadUser": "GET",
  "backstage/ReadUserGroup": "GET",
  "backstage/UnpublishProject": "PATCH",
  "backstage/UpdateBatch": "PATCH",
  "backstage/UpdateBroker": "PATCH",
  "backstage/UpdateFundMember": "PATCH",
  "backstage/UpdateFundPartner": "PATCH",
  "backstage/UpdateMember": "PATCH",
  "backstage/UpdateMyUser": "PATCH",
  "backstage/UpdateOption": "PATCH",
  "backstage/UpdatePartnerProject": "PATCH",
  "backstage/UpdateProject": "PATCH",
  "backstage/UpdateProperty": "PATCH",
  "backstage/UpdateSponsor": "PATCH",
  "backstage/UpdateSponsorPartner": "PATCH",
  "backstage/UpdateUserGroup": "PATCH",
  "campaigns/CloneCampaign": "PATCH",
  "campaigns/CreateCampaign": "POST",
  "campaigns/DeleteCampaign": "DELETE",
  "campaigns/IndexCampaigns": "GET",
  "campaigns/IndexRecipientReports": "GET",
  "campaigns/ListRecipientOptOuts": "GET",
  "campaigns/PublishCampaign": "PATCH",
  "campaigns/ReadCampaign": "GET",
  "campaigns/ReadCampaignMetrics": "GET",
  "campaigns/UnpublishCampaign": "PATCH",
  "campaigns/UpdateCampaign": "PATCH",
  "campaigns/UpdateCampaignTags": "PATCH",
  "documents/IndexDocusignWebhooks": "GET",
  "documents/ListTemplates": "GET",
  "identity/Authenticate": "PATCH",
  "identity/AuthenticateAs": "PATCH",
  "identity/ChangeMyIdentityEmail": "PATCH",
  "identity/ConfirmMyIdentityEmail": "PATCH",
  "investments/AcceptInvestment": "PATCH",
  "investments/AcceptTermsOfService": "PATCH",
  "investments/Authorize": "PATCH",
  "investments/BackupInvestment": "PATCH",
  "investments/ConnectAccountIdentity": "PATCH",
  "investments/CreateAccount": "POST",
  "investments/CreateAccountInvestment": "POST",
  "investments/CreateCommitment": "POST",
  "investments/CreateCustodian": "POST",
  "investments/CreateInvestment": "POST",
  "investments/DeleteCustodian": "DELETE",
  "investments/DeleteInvestment": "DELETE",
  "investments/DeleteMyInvestment": "DELETE",
  "investments/DisableAccount": "PATCH",
  "investments/DisableAccount2FA": "PATCH",
  "investments/DisableMyAccount2FA": "PATCH",
  "investments/EnableAccount": "PATCH",
  "investments/EnableAccount2FA": "PATCH",
  "investments/EnableMyAccount2FA": "PATCH",
  "investments/ImportAccounts": "POST",
  "investments/ImportInvestments": "POST",
  "investments/IndexAccountInvestments": "GET",
  "investments/IndexAccounts": "GET",
  "investments/IndexCustodians": "GET",
  "investments/IndexInvestments": "GET",
  "investments/IndexMyInvestments": "GET",
  "investments/IndexProjectInvestments": "GET",
  "investments/ListCustodians": "GET",
  "investments/PostponeCommitment": "PATCH",
  "investments/ReadAccount": "GET",
  "investments/ReadCustodian": "GET",
  "investments/ReadInvestment": "GET",
  "investments/ReadMyAccount": "GET",
  "investments/ReadMyInvestment": "GET",
  "investments/RejectInvestment": "PATCH",
  "investments/ResetAccountIdentity": "PATCH",
  "investments/ResetInvestmentSubscription": "PATCH",
  "investments/SearchInvestments": "GET",
  "investments/UpdateAccount": "PATCH",
  "investments/UpdateAccountNote": "PATCH",
  "investments/UpdateAccountTags": "PATCH",
  "investments/UpdateCustodian": "PATCH",
  "investments/UpdateCustodianSources": "PATCH",
  "investments/UpdateInvestment": "PATCH",
  "investments/UpdateInvestmentNote": "PATCH",
  "investments/UpdateInvestmentSigned": "PATCH",
  "investments/UpdateInvestmentSources": "PATCH",
  "investments/UpdateMyAccount": "PATCH",
  "investments/UpdateMyImmutableAccountInvestor": "PATCH",
  "investments/UpdateMyInvestment": "PATCH",
  "investments/UpdateMyInvestmentFundingStatus": "PATCH",
  "investments/UpdateMyInvestmentSignUrl": "PATCH",
  "investments/UpdateMyInvestmentSources": "PATCH",
  "investments/UpdateMyInvestmentSubscription": "PATCH",
  "investments/VerifyInvestment": "GET",
  "notifications/IndexNotifications": "GET",
  "notifications/ListTemplates": "GET",
  "notifications/ResetTemplate": "PATCH",
  "notifications/SendContactUsGuestNotification": "POST",
  "notifications/SendContactUsInvestorNotification": "POST",
  "notifications/SendDistributionPendingApprovalNotification": "POST",
  "notifications/SendInvestmentPendingRefundNotification": "POST",
  "notifications/SendNotification": "POST",
  "notifications/ToggleTemplate": "PATCH",
  "notifications/UpdateTemplate": "PATCH",
  "organizations/CreateMyOrganizationEmailIdentity": "POST",
  "organizations/CreateOrganization": "POST",
  "organizations/CreatePartnerOrganization": "POST",
  "organizations/DestroyOrganizationCertificates": "PATCH",
  "organizations/DestroyOrganizationCloudFrontDistribution": "PATCH",
  "organizations/DestroyOrganizationCognitoUsers": "PATCH",
  "organizations/DestroyOrganizationConfigurationSets": "PATCH",
  "organizations/DestroyOrganizationDnsRecords": "PATCH",
  "organizations/DestroyOrganizationDwollaCustomers": "PATCH",
  "organizations/DestroyOrganizationFiles": "PATCH",
  "organizations/DestroyOrganizationGateway": "PATCH",
  "organizations/DestroyOrganizationIdentities": "PATCH",
  "organizations/DestroyOrganizationPinpointProjects": "PATCH",
  "organizations/DestroyOrganizationTableRecords": "PATCH",
  "organizations/DisableOrganization": "PATCH",
  "organizations/EnableOrganization": "PATCH",
  "organizations/IndexOrganizations": "GET",
  "organizations/IndexPartnerOrganizations": "GET",
  "organizations/ReadMyOrganization": "GET",
  "organizations/ReadOrganizationMetrics": "GET",
  "organizations/ReadPublicOrganization": "GET",
  "organizations/ResetMyOrganizationEmailIdentity": "PATCH",
  "organizations/SetMyOrganizationController": "PATCH",
  "organizations/UpdateMyOrganization": "PATCH",
  "organizations/UpdateMyOrganizationDomainStatus": "PATCH",
  "organizations/UpdateMyOrganizationEmailVerificationStatus": "PATCH",
  "organizations/UpdateOrganization": "PATCH",
  "organizations/VerifyMyOrganizationEmailIdentity": "PATCH",
  "storage/CreateUploadUrls": "POST",
  "transactions/ApproveTransaction": "PATCH",
  "transactions/CertifyMyCustomerBeneficialOwnership": "PATCH",
  "transactions/ConnectCustomSource": "POST",
  "transactions/ConnectSource": "POST",
  "transactions/CreateBankAccount": "POST",
  "transactions/CreateDistributionTransaction": "POST",
  "transactions/CreateImpersonatedReceiveOnlyBusinessCustomer": "POST",
  "transactions/CreateImpersonatedUnverifiedBusinessCustomer": "POST",
  "transactions/CreateImpersonatedUnverifiedPersonalCustomer": "POST",
  "transactions/CreateMyCustomerBeneficialOwner": "PATCH",
  "transactions/CreateReceiveOnlyBusinessCustomer": "POST",
  "transactions/CreateUnverifiedBusinessCustomer": "POST",
  "transactions/CreateUnverifiedPersonalCustomer": "POST",
  "transactions/DeleteBankAccount": "DELETE",
  "transactions/DeleteExternalDistributionTransaction": "DELETE",
  "transactions/DeleteExternalFundingTransaction": "DELETE",
  "transactions/DeleteMyCustomerBeneficialOwner": "DELETE",
  "transactions/DeleteMySource": "DELETE",
  "transactions/DeleteTransaction": "DELETE",
  "transactions/FundInvestment": "POST",
  "transactions/IndexBankAccounts": "GET",
  "transactions/IndexCustomers": "GET",
  "transactions/IndexFailedTransactions": "GET",
  "transactions/IndexInvestorTransactions": "GET",
  "transactions/IndexMyTransactions": "GET",
  "transactions/IndexTransactions": "GET",
  "transactions/ListMyCustomerBeneficialOwners": "GET",
  "transactions/ListSourceBankAccounts": "GET",
  "transactions/ReadCustomer": "GET",
  "transactions/ReadMyCustomer": "GET",
  "transactions/ReadMyFundingBankAccount": "GET",
  "transactions/ReadSourceLink": "GET",
  "transactions/ReadTransaction": "GET",
  "transactions/RegisterExternalDistributionTransaction": "POST",
  "transactions/RegisterExternalFundingTransaction": "POST",
  "transactions/RejectTransaction": "PATCH",
  "transactions/RetryDistributionTransaction": "PATCH",
  "transactions/SubmitMySourceMicroDeposits": "PATCH",
  "transactions/TransferControllerCustomers": "POST",
  "transactions/UpdateMyCustomerBeneficialOwner": "PATCH",
  "transactions/UpdateMyCustomerVerificationStatus": "PATCH",
  "transactions/UpdateMySourceVerificationStatus": "PATCH",
  "transactions/UpdateRejectedTransactionAmount": "PATCH",
  "transactions/UpgradeMyUnverifiedBusinessCustomer": "PATCH",
  "transactions/UpgradeMyUnverifiedPersonalCustomer": "PATCH",
  "transactions/UploadBusinessCustomerDocuments": "PATCH",
  "transactions/UploadMyCustomerBeneficialOwnerDocument": "PATCH",
  "transactions/UploadPersonalCustomerDocument": "PATCH",
  "transactions/ValidateDistributionTransaction": "POST",
  "transactions/VerifyMyCustomSource": "PATCH"
}

export default HTTP_METHODS
