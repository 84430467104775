import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  investorAccountId: PropTypes.string,
  operationId: PropTypes.string.isRequired,
  parametersJson: PropTypes.string,
  resultJson: PropTypes.string,
  template: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  userId: PropTypes.string.isRequired
}

const IndexActivitiesOutputShapeShape = PropTypes.exact(properties)

export default IndexActivitiesOutputShapeShape
export { properties }
