import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import getExportColumns from "./getExportColumns"

const useExportColumns = () => {
  const {
    project = {},
    getTierName,
    getSponsorName,
    computeUserFullname,
  } = useOutletContext()

  return useMemo(() => getExportColumns({
    project,
    getTierName,
    getSponsorName,
    computeUserFullname
  }), [ project, getTierName, getSponsorName, computeUserFullname ])
}

export default useExportColumns
