import { useOutletContext } from "react-router-dom"

const LABEL_GUEST = "Guest"
const LABEL_SYSTEM = "System"
const LABEL_UNDEFINED = "Undefined"


const useComputeUser = (getUserId, isInvestor, isBackstageUser, isUuid) => {
  const { computeUserFullname, computeAccountFullname } = useOutletContext()

  const compute = (activity) => {
    const { userId } = activity

    const id = getUserId(userId)

    if (isInvestor(id)) {
      return computeAccountFullname(id)
    }

    if (isBackstageUser(id)) {
      return computeUserFullname(id)
    }

    const isGuest = id === "USR_GUEST"

    if (isGuest) {
      return LABEL_GUEST
    }

    const isSystem = id === "SYSTEM"

    if (isSystem) {
      return LABEL_SYSTEM
    }

    const isRoot = id === "USR_ROOT"

    if (isRoot) {
      return LABEL_SYSTEM
    }

    const isCorrelationId = isUuid(id)

    if (isCorrelationId) {
      return LABEL_SYSTEM
    }

    const isEmail = id.includes("@")

    if (isEmail) {
      return id
    }

    return LABEL_UNDEFINED
  }

  return compute
}

export default useComputeUser
