import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { VirtualTable } from "@components/Table"
import { useAppContext } from "@components/AppContext"
import { OperationShape } from "@api"
import { indexActivitiesOperation as defaultIndexOperation } from "@api/services/activities"

import getUser from "./helpers/getUser"
import renderTemplate from "./helpers/renderTemplate"
import useColumnsConfig from "./helpers/useColumnsConfig"
import getExportColumns from "./helpers/getExportColumns"
import ActivitiesTableHeader from "./ActivitiesTableHeader"
import useIndexOperationParameters from "./helpers/useIndexOperationParameters"


const ActivitiesTable = ({
  indexOperation = defaultIndexOperation,
  investorAccountId = undefined,
  table,
  openActivityDrawer
}) => {
  const { isConsoleOrganization } = useAppContext()

  const [ dateRange, updateOperationParameters ] = useIndexOperationParameters(table)

  const operationParameters = useMemo(() => ({
    ...dateRange,
    investorAccountId
  }), [ dateRange, investorAccountId ])

  const outletContext = useOutletContext()

  const {
    members,
    accounts,
    projects,
    membersMap,
    accountsMap,
  } = outletContext

  // eslint-disable-next-line no-warning-comments
  // TODO: Should be simplified for new backstage layout:
  const isReady = isConsoleOrganization || (!!projects && !!accounts && !!members)

  table.current.getUser = userId =>
    getUser(userId, membersMap, accountsMap)

  table.current.renderActivity = ({ template }) =>
    renderTemplate(outletContext, template)

  const headerRenderTitle = (title, items) => (
    <ActivitiesTableHeader
      title={title}
      items={items}
      updateOperationParameters={updateOperationParameters}
    />
  )

  const size = "small"
  const sticky = true
  const columnsConfig = useColumnsConfig(table, openActivityDrawer)
  const exportColumns = getExportColumns(outletContext)

  const exportFileName = "activities"
  const defaultSortColumn = "createdAt"
  const defaultSortColumnDirection = "descend"

  const tableProps = {
    size,
    table,
    sticky,
    isReady,
    exportColumns,
    columnsConfig,
    indexOperation,
    exportFileName,
    defaultSortColumn,
    headerRenderTitle,
    operationParameters,
    defaultSortColumnDirection,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

ActivitiesTable.propTypes = {
  table: PropTypes.shape().isRequired,
  indexOperation: OperationShape,
  investorAccountId: PropTypes.string,
  openActivityDrawer: PropTypes.func.isRequired,
}

export default ActivitiesTable
