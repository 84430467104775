import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Space, Row, Col, Tag, Typography, Spin, Collapse } from "antd"

import { W1 } from "@components"
import { Drawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { readActivityOperation } from "@api/services/activities"

import ActivityHeaderDescriptions from "./ActivityHeaderDescriptions"
import ActivitySummaryDescriptions from "./ActivitySummaryDescriptions"
import ActivityAttributesDescriptions from "./ActivityAttributesDescriptions"

const LABEL_ACTIVITY = "Activity"


const ActivityDrawer = ({ drawer, renderActivity }) => {
  const [ activity, setActivity ] = useState()
  const [ activityId, setActivityId ] = useState()

  const { request } = useAppContext()

  const onOpen = ({ id }) => {
    setActivity()
    setActivityId(id)
    request(readActivityOperation, { id })
      .then(({ data }) => setActivity(data))
  }

  const title = useMemo(() =>
    activityId
      ? (
          <Row>
            <Col flex="auto">
              <Typography.Title level={5} style={{ margin: 0 }}>
                {LABEL_ACTIVITY}
              </Typography.Title>
            </Col>
            <Col>
              <Tag bordered={false}>
                {activityId}
              </Tag>
            </Col>
          </Row>
        )
      : ""
  , [ activityId ])

  const getAttributeItems = () =>
    [
      {
        key: '1',
        label: 'Query',
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="queryAttributes" />,
      },
      {
        key: '2',
        label: 'Mutation',
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="mutationAttributes" />,
      },
      {
        key: '3',
        label: 'Output',
        children: <ActivityAttributesDescriptions activity={activity} attributeKey="resultAttributes" />,
      },
    ]

  return (
    <Drawer
      title={title}
      drawer={drawer}
      onOpen={onOpen}
      destroyOnClose={true}
    >
      {
        !activity && (
          <center>
            <Spin />
          </center>
        )
      }

      {
        activity && (
          <Space
            size={W1}
            style={{ width: "100%" }}
            direction="vertical"
          >
            <ActivityHeaderDescriptions
              activity={activity}
            />

            <ActivitySummaryDescriptions
              activity={activity}
              renderActivity={renderActivity}
            />

            <Collapse
              size="small"
              items={getAttributeItems()}
              defaultActiveKey={['1', '2', '3']}
            />
          </Space>
        )
      }
    </Drawer>
  )
}

ActivityDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  renderActivity: PropTypes.func.isRequired,
}

export default ActivityDrawer
