import React, { useRef, useCallback } from "react"

import Page from "@components/Page"
import { W1 } from "@components"

import ActivityDrawer from "./ActivityDrawer"
import ActivitiesTable from "./ActivitiesTable"

const LABEL_TITLE = "Activity"


const ActivitiesPage = () => {
  const table = useRef({})
  const drawer = useRef({})

  const onSearch = value =>
    table.current.search(value)

  const openActivityDrawer = activityId => {
    const item = table.current.getItem(activityId)
    drawer.current.open(item)
  }

  const renderActivity = useCallback(
    activity => table.current.renderActivity(activity)
  , [])

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <ActivitiesTable
        table={table}
        openActivityDrawer={openActivityDrawer}
      />

      <ActivityDrawer
        drawer={drawer}
        renderActivity={renderActivity}
      />
    </Page>
  )
}

export default ActivitiesPage
