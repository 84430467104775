import getOperation from "../../helpers/getOperation"

import CreateMyOrganizationEmailIdentityOutputShape from "./CreateMyOrganizationEmailIdentityOutputShape"
import CreateOrganizationOutputShape from "./CreateOrganizationOutputShape"
import CreatePartnerOrganizationOutputShape from "./CreatePartnerOrganizationOutputShape"
import DestroyOrganizationCertificatesOutputShape from "./DestroyOrganizationCertificatesOutputShape"
import DestroyOrganizationCloudFrontDistributionOutputShape from "./DestroyOrganizationCloudFrontDistributionOutputShape"
import DestroyOrganizationCognitoUsersOutputShape from "./DestroyOrganizationCognitoUsersOutputShape"
import DestroyOrganizationConfigurationSetsOutputShape from "./DestroyOrganizationConfigurationSetsOutputShape"
import DestroyOrganizationDnsRecordsOutputShape from "./DestroyOrganizationDnsRecordsOutputShape"
import DestroyOrganizationDwollaCustomersOutputShape from "./DestroyOrganizationDwollaCustomersOutputShape"
import DestroyOrganizationFilesOutputShape from "./DestroyOrganizationFilesOutputShape"
import DestroyOrganizationGatewayOutputShape from "./DestroyOrganizationGatewayOutputShape"
import DestroyOrganizationIdentitiesOutputShape from "./DestroyOrganizationIdentitiesOutputShape"
import DestroyOrganizationPinpointProjectsOutputShape from "./DestroyOrganizationPinpointProjectsOutputShape"
import DestroyOrganizationTableRecordsOutputShape from "./DestroyOrganizationTableRecordsOutputShape"
import DisableOrganizationOutputShape from "./DisableOrganizationOutputShape"
import EnableOrganizationOutputShape from "./EnableOrganizationOutputShape"
import IndexOrganizationsOutputShape from "./IndexOrganizationsOutputShape"
import IndexPartnerOrganizationsOutputShape from "./IndexPartnerOrganizationsOutputShape"
import ReadMyOrganizationOutputShape from "./ReadMyOrganizationOutputShape"
import ReadOrganizationMetricsOutputShape from "./ReadOrganizationMetricsOutputShape"
import ReadPublicOrganizationOutputShape from "./ReadPublicOrganizationOutputShape"
import ResetMyOrganizationEmailIdentityOutputShape from "./ResetMyOrganizationEmailIdentityOutputShape"
import SetMyOrganizationControllerOutputShape from "./SetMyOrganizationControllerOutputShape"
import UpdateMyOrganizationDomainStatusOutputShape from "./UpdateMyOrganizationDomainStatusOutputShape"
import UpdateMyOrganizationEmailVerificationStatusOutputShape from "./UpdateMyOrganizationEmailVerificationStatusOutputShape"
import UpdateMyOrganizationOutputShape from "./UpdateMyOrganizationOutputShape"
import UpdateOrganizationOutputShape from "./UpdateOrganizationOutputShape"
import VerifyMyOrganizationEmailIdentityOutputShape from "./VerifyMyOrganizationEmailIdentityOutputShape"

export {
  CreateMyOrganizationEmailIdentityOutputShape,
  CreateOrganizationOutputShape,
  CreatePartnerOrganizationOutputShape,
  DestroyOrganizationCertificatesOutputShape,
  DestroyOrganizationCloudFrontDistributionOutputShape,
  DestroyOrganizationCognitoUsersOutputShape,
  DestroyOrganizationConfigurationSetsOutputShape,
  DestroyOrganizationDnsRecordsOutputShape,
  DestroyOrganizationDwollaCustomersOutputShape,
  DestroyOrganizationFilesOutputShape,
  DestroyOrganizationGatewayOutputShape,
  DestroyOrganizationIdentitiesOutputShape,
  DestroyOrganizationPinpointProjectsOutputShape,
  DestroyOrganizationTableRecordsOutputShape,
  DisableOrganizationOutputShape,
  EnableOrganizationOutputShape,
  IndexOrganizationsOutputShape,
  IndexPartnerOrganizationsOutputShape,
  ReadMyOrganizationOutputShape,
  ReadOrganizationMetricsOutputShape,
  ReadPublicOrganizationOutputShape,
  ResetMyOrganizationEmailIdentityOutputShape,
  SetMyOrganizationControllerOutputShape,
  UpdateMyOrganizationDomainStatusOutputShape,
  UpdateMyOrganizationEmailVerificationStatusOutputShape,
  UpdateMyOrganizationOutputShape,
  UpdateOrganizationOutputShape,
  VerifyMyOrganizationEmailIdentityOutputShape
}

export const createMyOrganizationEmailIdentityOperation = getOperation("organizations/CreateMyOrganizationEmailIdentity")
export const createOrganizationOperation = getOperation("organizations/CreateOrganization")
export const createPartnerOrganizationOperation = getOperation("organizations/CreatePartnerOrganization")
export const destroyOrganizationCertificatesOperation = getOperation("organizations/DestroyOrganizationCertificates")
export const destroyOrganizationCloudFrontDistributionOperation = getOperation("organizations/DestroyOrganizationCloudFrontDistribution")
export const destroyOrganizationCognitoUsersOperation = getOperation("organizations/DestroyOrganizationCognitoUsers")
export const destroyOrganizationConfigurationSetsOperation = getOperation("organizations/DestroyOrganizationConfigurationSets")
export const destroyOrganizationDnsRecordsOperation = getOperation("organizations/DestroyOrganizationDnsRecords")
export const destroyOrganizationDwollaCustomersOperation = getOperation("organizations/DestroyOrganizationDwollaCustomers")
export const destroyOrganizationFilesOperation = getOperation("organizations/DestroyOrganizationFiles")
export const destroyOrganizationGatewayOperation = getOperation("organizations/DestroyOrganizationGateway")
export const destroyOrganizationIdentitiesOperation = getOperation("organizations/DestroyOrganizationIdentities")
export const destroyOrganizationPinpointProjectsOperation = getOperation("organizations/DestroyOrganizationPinpointProjects")
export const destroyOrganizationTableRecordsOperation = getOperation("organizations/DestroyOrganizationTableRecords")
export const disableOrganizationOperation = getOperation("organizations/DisableOrganization")
export const enableOrganizationOperation = getOperation("organizations/EnableOrganization")
export const indexOrganizationsOperation = getOperation("organizations/IndexOrganizations")
export const indexPartnerOrganizationsOperation = getOperation("organizations/IndexPartnerOrganizations")
export const readMyOrganizationOperation = getOperation("organizations/ReadMyOrganization")
export const readOrganizationMetricsOperation = getOperation("organizations/ReadOrganizationMetrics")
export const readPublicOrganizationOperation = getOperation("organizations/ReadPublicOrganization")
export const resetMyOrganizationEmailIdentityOperation = getOperation("organizations/ResetMyOrganizationEmailIdentity")
export const setMyOrganizationControllerOperation = getOperation("organizations/SetMyOrganizationController")
export const updateMyOrganizationOperation = getOperation("organizations/UpdateMyOrganization")
export const updateMyOrganizationDomainStatusOperation = getOperation("organizations/UpdateMyOrganizationDomainStatus")
export const updateMyOrganizationEmailVerificationStatusOperation = getOperation("organizations/UpdateMyOrganizationEmailVerificationStatus")
export const updateOrganizationOperation = getOperation("organizations/UpdateOrganization")
export const verifyMyOrganizationEmailIdentityOperation = getOperation("organizations/VerifyMyOrganizationEmailIdentity")
