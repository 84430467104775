import React, {useState} from "react"
import PropTypes from "prop-types"
import { Progress } from "antd"

import { Modal } from "@components/Page"

import useExportInvestments from "./helpers/useExportInvestments"

const MODAL_WIDTH = 982
const LABEL_TITLE = "Export Investments"


const ExportInvestmentsBlockingModal = ({ modal }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const [progress, exportBankAccounts] = useExportInvestments(setIsOpen)

  modal.current.open = () => {
    setIsOpen(true)
    exportBankAccounts()
  }

  const onClose = () =>
    setIsOpen(false)


  return (
    <Modal
      width={MODAL_WIDTH}
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      destroyOnClose={true}
      closable={false}
    >
      <Progress percent={progress} />
    </Modal>
  )
}


ExportInvestmentsBlockingModal.propTypes = {
  modal: PropTypes.shape().isRequired
}

export default ExportInvestmentsBlockingModal