import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  fundsRaisedAmount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  investmentsEntityCount: PropTypes.number.isRequired,
  investmentsIndividualCount: PropTypes.number.isRequired,
  investmentsJointCount: PropTypes.number.isRequired,
  investmentsSdiraCount: PropTypes.number.isRequired,
  investmentsTotalCount: PropTypes.number.isRequired,
  investorsTotalCount: PropTypes.number.isRequired,
  projectArchivedCount: PropTypes.number.isRequired,
  projectClosedCount: PropTypes.number.isRequired,
  projectsDraftCount: PropTypes.number.isRequired,
  projectsOpenCount: PropTypes.number.isRequired,
  projectsTotalCount: PropTypes.number.isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadOrganizationMetricsOutputShapeShape = PropTypes.exact(properties)

export default ReadOrganizationMetricsOutputShapeShape
export { properties }
