import { getAddress } from "@components/Address"
import { getUsDateFromIsoDate } from "@components/Date"
import { computeInvestmentAmount } from "@components/Investment"
import { getFormattedDecimalValue, getFormattedAmountValue } from "@components/Amount"
import {
  BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP,
  ENTITY_MEMBERSHIP_INTEREST_TRUSTEE,
  ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP,
  JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP,
  ENTITY_TYPE_TITLES_MAP,
  INVESTMENT_PROFILE_FIELD_MAP,
  PROJECT_TYPES
} from "@components/Domain"

import getEntityName from "./getEntityName"
import getAccreditation from "./getAccreditation"


const getTotalByTier = (tier, investments) => {
  const amountValue = investments
    .filter(({ projectTierId }) => projectTierId === tier.name)
    .map(({ amount }) => amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  return getFormattedAmountValue(amountValue)
}

const residenceValue = ({ isUsPerson, hasOtherUsResidenceState, residenceState }, addressRegion) => {
  if (isUsPerson) {
    return hasOtherUsResidenceState ? residenceState : addressRegion
  }

  return "Not a resident of US"
}

const getAccreditationValue = accreditationValue => {
  const isNoAccreditation = accreditationValue === "No"
  const isAccreditation = accreditationValue === "Yes"

  if (isNoAccreditation) {
    return "No Accreditation"
  }

  if (isAccreditation) {
    return "Accreditation Verified"
  }

  return "Unverified Accreditation"
}

const getExportColumns = ({
  project,
  getTierName,
  getSponsorName,
  computeUserFullname
}) => {
  const {
    type: projectType,
    state: projectState,
    tiers: projectTiers,
    closeDate: projectCloseDate,
  } = project

  const isNonFund = projectType !== PROJECT_TYPES.FUND

  const getInvestmentClasses = () => {
    return projectTiers.map(tier => {
      return {
        title: getTierName(tier),
        value: ({ investments }) => getTotalByTier(tier, investments)
      }
    })
  }

  return [
    {
      title: "Project Name",
      value: ({projectName}) => projectName || project.name
    },
    {
      title: "Entity Name",
      value: ({ investmentProject }) => {
        const currentProject = investmentProject ? investmentProject : project

        return getEntityName(currentProject) || "-"
      }
    },
    {
      title: "Sponsor",
      value: ({ sponsorId }) => {
        return getSponsorName(sponsorId)
      }
    },
    {
      title: "Liaison",
      value: ({ brokerUserId }) => {
        return computeUserFullname(brokerUserId)
      }
    },
    {
      title: "Investment ID",
      value: ({ id }) => id,
    },
    {
      title: "Profile Name",
      value: ({name}) => name,
    },
    {
      title: "Legal Full Name",
      value: ({ investor }) => {
        const {givenName, familyName, additionalName} = investor

        return [
          givenName,
          additionalName,
          familyName
        ].join(" ")
      }
    },
    {
      title: "DOB",
      value: ({ investor }) => investor.dateOfBirth ? getUsDateFromIsoDate(investor.dateOfBirth) : ""
    },
    {
      title: "Mailing Address",
      value: ({ investor }) => getAddress(investor.address)
    },
    {
      title: "Contact Phone Number",
      value: ({ investor }) => investor.mobileNumber
    },
    {
      title: "Email Address",
      value: ({ investor }) => investor.email
    },
    {
      title: "Additional Details",
      value: investment => {
        const { isFunded, investor, profileType } = investment
        const { hasAccreditation, accreditationExpiresAt } = investor
        const { value: accreditationValue } = getAccreditation(hasAccreditation, accreditationExpiresAt, projectCloseDate, projectState, isFunded, isNonFund)
        const accreditation = getAccreditationValue(accreditationValue)

        const profile = investment[INVESTMENT_PROFILE_FIELD_MAP[profileType]]
        if (!profile) {
          return accreditation
        }

        const { isUsCitizen } = profile
        const isUsCitizenDefined = isUsCitizen !== undefined

        if (!isUsCitizenDefined) {
          return accreditation
        }

        const citizenship = isUsCitizen ? "is US citizen" : "is not US citizen"

        return [accreditation, citizenship].join(", ")
      }
    },
    {
      title: "Title of membership interests",
      value: ({ profileIndividual, profileJoint, profileEntity }) => {
        if (profileIndividual) {
          const {isMarriedAndSeparateProperty} = profileIndividual

          return isMarriedAndSeparateProperty ?
            "A married person, as my sole and separate property" :
            "Individually, as a single person"
        }

        if (profileJoint) {
          const {membershipInterestsTitle} = profileJoint

          return JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]
        }

        if (profileEntity) {
          const {membershipInterestsTitle} = profileEntity

          return ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP[membershipInterestsTitle]?.replace("As ", "")
        }
      }
    },
    {
      title: "Investor Legal Full Name",
      value: ({ investor }) => {
        const {givenName, familyName, additionalName} = investor
        return [
          givenName,
          additionalName,
          familyName
        ].join(" ")
      }
    },
    {
      title: "SSN/ITIN",
      value: ({ profileJoint }) => {
        if (profileJoint) {
          return profileJoint.ssnNumber
        }
      }
    },
    {
      title: "Co-Investor Legal Full Name",
      value: ({ profileJoint }) => {
        if (profileJoint) {
          const {
            jointGivenName,
            jointFamilyName,
            jointAdditionalName,
          } = profileJoint

          return [
            jointGivenName,
            jointAdditionalName,
            jointFamilyName
          ].join(" ")
        }
      }
    },
    {
      title: "Co-Investor Email",
      value: ({ profileJoint }) => {
        if (profileJoint) {
          return profileJoint.jointEmail
        }
      }
    },
    {
      title: "Address",
      value: ({ profileJoint }) => {
        if (profileJoint) {
          return getAddress(profileJoint.jointAddress)
        }
      }
    },
    {
      title: "Entity's Full Legal Name",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {membershipInterestsTitle} = profileEntity
          const isTrustee = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE

          return !isTrustee ? profileEntity.name : ""
        }
      }
    },
    {
      title: "Trust's Full Legal Name",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {membershipInterestsTitle} = profileEntity
          const isTrustee = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE

          return isTrustee ? profileEntity.name : ""
        }
      }
    },
    {
      title: "EIN",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          return profileEntity.einNumber
        }
      }
    },
    {
      title: "Entity Type",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {entityType} = profileEntity

          return ENTITY_TYPE_TITLES_MAP[entityType]
        }
      }
    },
    {
      title: "Tax Filing Status",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const { beneficialOwnerTaxFilingStatus, isDisregardedForTaxPurposes } = profileEntity

          if (isDisregardedForTaxPurposes) {
            return "-"
          }

          return beneficialOwnerTaxFilingStatus ? BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[beneficialOwnerTaxFilingStatus] : ""
        }
      }
    },
    {
      title: "Retirement Plan (IRA/SEP/Keogh/etc.)",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {isRetirementPlan} = profileEntity

          if (isRetirementPlan !== undefined) {
            return isRetirementPlan ? "Yes" : "No"
          }
        }
      }
    },
    {
      title: "Status (e.g. Disregarded or not)",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {isDisregardedForTaxPurposes} = profileEntity

          return isDisregardedForTaxPurposes ?
            "Disregarded for federal income tax purposes" :
            "Not disregarded for federal income tax purposes"
        }
      }
    },
    {
      title: "Beneficial Owner Name",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          return profileEntity.beneficialOwnerName
        }
      }
    },
    {
      title: "Beneficial Owner Entity Type",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const {beneficialOwnerEntityType} = profileEntity

          return beneficialOwnerEntityType ? ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP[beneficialOwnerEntityType] : ""
        }
      }
    },
    {
      title: "Beneficial Owner Tax Filing Status",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const { beneficialOwnerTaxFilingStatus, isDisregardedForTaxPurposes } = profileEntity

          if (!isDisregardedForTaxPurposes) {
            return "-"
          }

          return beneficialOwnerTaxFilingStatus ? BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[beneficialOwnerTaxFilingStatus] : ""
        }
      }
    },
    {
      title: "Beneficial Owner (Custodian) EIN",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          return profileEntity.beneficialOwnerEinNumber
        }
      }
    },
    {
      title: "Beneficial Owner SSN/ITIN",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const { isDisregardedForTaxPurposes } =  profileEntity

          return isDisregardedForTaxPurposes ? profileEntity.beneficialOwnerSsnNumber : "-"
        }
      }
    },
    {
      title: "Beneficial Owner Address",
      value: ({ profileEntity }) => {
        if (profileEntity) {
          const { isDisregardedForTaxPurposes } =  profileEntity

          return isDisregardedForTaxPurposes ? getAddress(profileEntity.beneficialOwnerAddress) : "-"
        }
      }
    },
    {
      title: "Full Legal SDIRA Account Name",
      value: ({ profileSdira }) => {
        if (profileSdira) {
          return profileSdira.accountName
        }
      }
    },
    {
      title: "Custodian Name",
      value: ({ profileSdira }) => {
        if (profileSdira) {
          return profileSdira.custodianName
        }
      }
    },
    {
      title: "Custodian EIN",
      value: ({ profileSdira }) => {
        if (profileSdira) {
          return profileSdira.custodianEinNumber
        }
      }
    },
    {
      title: "Custodian Address",
      value: ({ profileSdira }) => {
        if (profileSdira) {
          return getAddress(profileSdira.custodianAddress)
        }
      }
    },
    {
      title: "State of Residence",
      value: ({ profileIndividual, profileJoint, profileEntity, profileSdira, investor }) => {
        let profile = null
        let region = null

        if (profileIndividual) {
          profile = profileIndividual
          const { address } = investor
          region = address?.addressRegion
        }

        if (profileJoint) {
          profile = profileJoint
          region = (profileJoint.jointAddress || {}).addressRegion
        }

        if (profileEntity) {
          profile = profileEntity
          const { address, beneficialOwnerAddress } = profileEntity
          region = address?.addressRegion || beneficialOwnerAddress?.addressRegion
        }

        if (profileSdira) {
          profile = profileSdira
          const { address } = investor
          region = address?.addressRegion
        }

        if (profile) {
          return residenceValue(profile, region)
        }
      }
    },
    {
      title: "Non-US Person",
      value: investment => {
        const { profileType } = investment
        let value = "No";

        const profile = investment[INVESTMENT_PROFILE_FIELD_MAP[profileType]]
        if (!profile) {
          return "-"
        }

        const { isUsPerson } = profile
        const isUsPersonDefined = isUsPerson !== undefined

        if (!isUsPersonDefined) {
          return ""
        }

        if (isUsPerson) {
          value = "Yes"
        }

        return value
      }
    },
    ...getInvestmentClasses(),
    {
      title: "Distribution Account Mask",
      value: ({receiverSourceName}) => receiverSourceName
    },
    {
      title: "Subscription Status",
      value: ({ isSigned }) => isSigned ? "SIGNED" : "PENDING"
    },
    {
      title: "Total Committed",
      value: investment => getFormattedAmountValue(computeInvestmentAmount(investment))
    },
    {
      title: "Funds Received",
      value: ({ receivedAmount }) => getFormattedAmountValue(receivedAmount)
    }
  ]
}

export default getExportColumns
