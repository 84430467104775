import React, { useEffect, useRef } from "react"
import { useParams, useOutletContext } from "react-router-dom"
import { ExportOutlined, TagsOutlined } from "@ant-design/icons"

import { W1 } from "@components"
import { Page } from "@components/Page"
import { TagsDrawer } from "@components/Tags"
import { ACCOUNT_TAG } from "@components/Store/BackstageStore/useOptionsStore"
import { InvestorDrawer } from "@components/Investor"

import InvestorsTable from "./InvestorsTable"
import InvestorCreateModal from "./InvestorCreateModal"
import ExportInvestmentsBlockingModal
  from "./ExportInvestmentsBlockingModal/ExportInvestmentsBlockingModal"

const LABEL_TAGS = "Edit Tags"
const LABEL_TITLE = "Investors"
const LABEL_EXPORT_INVESTMENTS = "Export Investments"


const AccountsPage = () => {
  const { investorId } = useParams()
  const { accountTags } = useOutletContext()

  const table = useRef({})
  const tagsDrawer = useRef({})
  const createModal = useRef({})
  const investorDrawer = useRef({})
  const exportInvestmentsModal = useRef({})

  useEffect(() => {
    if (!investorId) {
      return
    }

    investorDrawer.current.open(investorId)
  }, [ investorId ])

  const onSearch = value =>
    table.current.search(value)

  const openTagsDrawer = () =>
    tagsDrawer.current.open()

  const openExportInvestments = () =>
    exportInvestmentsModal.current.open()

  const openCreateModal = () =>
    createModal.current.open()

  const openInvestorDrawer = id => {
    window.history.pushState({}, null, `/backstage/investors/${id}`)
    investorDrawer.current.open(id)
  }

  const onCloseInvestorDrawer = () =>
    window.history.pushState({}, null, "/backstage/investors/")

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  const menu = [
    {
      key: "tags",
      icon: <TagsOutlined />,
      label: LABEL_TAGS,
      onClick: openTagsDrawer
    },
    {
      key: "investments",
      icon: <ExportOutlined />,
      label: LABEL_EXPORT_INVESTMENTS,
      onClick: openExportInvestments
    }
  ]

  const pageProps = {
    title: LABEL_TITLE,
    menu,
    style,
    onSearch,
  }

  return (
    <Page {...pageProps}>
      <InvestorsTable
        table={table}
        openTagsDrawer={openTagsDrawer}
        openCreateModal={openCreateModal}
        openInvestorDrawer={openInvestorDrawer}
      />

      <InvestorCreateModal
        modal={createModal}
        openInvestorDrawer={openInvestorDrawer}
      />

      <InvestorDrawer
        drawer={investorDrawer}
        onClose={onCloseInvestorDrawer}
      />

      <TagsDrawer
        group={ACCOUNT_TAG}
        drawer={tagsDrawer}
        tagOptions={accountTags}
      />

      <ExportInvestmentsBlockingModal
        modal={exportInvestmentsModal}
      />
    </Page>
  )
}

export default AccountsPage
