import { useState, useMemo } from "react"
import { usePapaParse } from "react-papaparse"
import {useOutletContext} from "react-router-dom"

import { downloadContent } from "@components/Import"
import {useAppContext} from "@components/AppContext"
import {
  indexProjectInvestmentsOperation
} from "@api/services/investments"
import getExportColumns
  from "@modules/backstage/investments/InvestmentsTable/helpers/getExportColumns"

const useExportInvestments = (setIsOpen) => {
  const { jsonToCSV } = usePapaParse()
  const {
    projects,
    getTierName,
    getSponsorName,
    computeUserFullname,
  } = useOutletContext()
  const { request, getOrganizationId } = useAppContext()
  const [ progress, setProgress ] = useState(0)

  const operationsCount = useMemo(() => projects.length, [projects])
  const currentOrganizationId = getOrganizationId()

  const fileName = `${currentOrganizationId}_investments`

  const callback = async () => {
    setProgress(0)
    const rows = []
    let index = 1
    for (const project of projects) {
      const {
        id: projectId,
      } = project

      const { data: projectInvestments } = await request(indexProjectInvestmentsOperation.id, {projectId})

      for (const investment of projectInvestments) {
        const columns = getExportColumns({
          project,
          getTierName,
          getSponsorName,
          computeUserFullname,
        })

        const row = {}
        for (const column of columns) {
          const { title, value } = column
          row[title] = value(investment)
        }

        rows.push(row)
      }

      setProgress(Math.ceil(index * 100 / operationsCount))
      index++
    }

    const content = jsonToCSV(rows)

    downloadContent(`${fileName}.csv`, content, 'text/csv', true)

    setIsOpen(false)
  }

  return [ progress, callback ]
}

export default useExportInvestments