import React, { useState } from "react"
import PropTypes from "prop-types"
import { Space } from "antd"

import { Modal } from "@components/Page"
import { W1, MODAL_WIDTH2 } from "@components"

import GoToInvestmentForm from "./GoToInvestmentForm"

const LABEL_TITLE = "Go To Investment"


const GoToInvestmentModal = ({ modal }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  modal.current.open = () => setIsOpen(true)

  const onClose = () => {
    setIsOpen(false)
  }

  const modalProps = {
    title: LABEL_TITLE,
    width: MODAL_WIDTH2,
    destroyOnClose: true,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <Space direction="vertical" size={W1}>
        <GoToInvestmentForm onSuccess={onClose} />
      </Space>
    </Modal>
  )
}

GoToInvestmentModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default GoToInvestmentModal
