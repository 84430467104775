import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { WarningOutlined } from "@ant-design/icons"
import { Typography, Space, Avatar, Tooltip, theme } from "antd"

import { ROLES, ROLES_MAP } from "@components/Domain"

import getColors from "./helpers/getColors"

const { Text, Link: TextLink } = Typography

const ROOT = "USR_ROOT"
const GUEST = "USR_GUEST"
const SYSTEM = "SYSTEM"


const Identity = ({
  size = "small",
  strong = false,
  onClick = null,
  children = undefined,
  hasAvatar = true,
  shouldFallbackToId = false,
  id,
}) => {
  const { token: { colorBgLayout } } = theme.useToken()
  const { membersMap, brokersMap, accountsMap = {} } = useOutletContext()

  const isLink = !!onClick

  const renderValue = (value) => {
    if (isLink) {
      return (
        <TextLink strong={strong} onClick={onClick} style={{ whiteSpace: "nowrap" }}>
          {value}
        </TextLink>
      )
    }

    return (
      <Text strong={strong} style={{ whiteSpace: "nowrap" }}>
        {value}
      </Text>
    )
  }

  const isGuest = id === GUEST

  if (isGuest) {
    return renderValue("Guest")
  }

  const isSystem = [ SYSTEM, ROOT ].includes(id)

  if (isSystem) {
    return renderValue("System")
  }

  const identity = accountsMap[id] || brokersMap[id] || membersMap[id]

  if (!identity) {
    if (shouldFallbackToId) {
      return renderValue(id)
    }

    return ""
  }

  const isInvestor = id.startsWith('ACC_')

  const { givenName, additionalName, familyName } = isInvestor
    ? identity.investor
    : identity

  const fullName = [
    givenName,
    additionalName,
    familyName
  ].join(' ')

  const { status, isDisabled, disableReason } = identity

  const roles = isInvestor
    ? [ ROLES.INVESTOR ]
    : identity.roles || []

  const [ mainRoleId ] = roles
  const role = ROLES_MAP[mainRoleId]

  if (!role) {
    return children || fullName
  }

  const getAvatarStyles = () =>
    getColors(mainRoleId, role, status, colorBgLayout)

  const getInitials = () =>
    fullName
      .split(" ")
      .map(word => word[0])
      .join("")

  return (
    <Space>
      {
        hasAvatar && (
          <Avatar
            size={size}
            style={getAvatarStyles()}
          >
            {getInitials()}
          </Avatar>
        )
      }

      {
        renderValue(children || fullName)
      }

      {
        isDisabled && (
          <Tooltip
            placement="top"
            title={
              disableReason
                ? ['Account Disabled', disableReason].join(': ')
                : 'Account Disabled'
            }
          >
            <WarningOutlined />
          </Tooltip>
        )
      }
    </Space>
  )
}

Identity.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  strong: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  hasAvatar: PropTypes.bool,
  shouldFallbackToId: PropTypes.bool,
}

export default Identity
